// Models TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
// @ts-ignore
import dayjs, { Dayjs } from "dayjs";
import InterfaceConstructor from './InterfaceConstructor';
import IotModel from 'src/consts/IotModel';
import AutomationType from 'src/consts/AutomationType';

interface BinInfoDTO {
    id: number;
    growerId: number;
    growerName: string | null;
    name: string | null;
    ecDeviceID: string | null;
    deviceId: string | null;
    notes: string | null;
    binLat: number | null;
    binLng: number | null;
    manufacturer: string | null;
    fanModel: string | null;
    estimatedCapacityBu: number | null;
    isActive: boolean;
    iotModel: IotModel | null;
    isLegacyPremier: boolean;
    eaveHeightFt: number | null;
    peakHeightFt: number | null;
    plenumHeightFt: number | null;
    diameterFt: number | null;
    peakCapDiameterFt: number | null;
    roofPitchDegrees: number | null;
    linkIpAddress: string | null;
    automationType: AutomationType | null;
    isPremier: boolean;
}
const BinInfoDTO: InterfaceConstructor<BinInfoDTO> = {
    create: (initValues?: {} | null | undefined) => {
        return Object.assign(
        {
            id: 0,
            growerId: 0,
            growerName: null,
            name: null,
            ecDeviceID: null,
            deviceId: null,
            notes: null,
            binLat: null,
            binLng: null,
            manufacturer: null,
            fanModel: null,
            estimatedCapacityBu: null,
            isActive: false,
            iotModel: 0,
            isLegacyPremier: false,
            eaveHeightFt: null,
            peakHeightFt: null,
            plenumHeightFt: null,
            diameterFt: null,
            peakCapDiameterFt: null,
            roofPitchDegrees: null,
            linkIpAddress: null,
            automationType: AutomationType.DriStack,
            isPremier: false,
        },
        initValues);
    }
};

export default BinInfoDTO;
