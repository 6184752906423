import { Col, Layout, notification, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { strictParseInt } from "../BinStatusPage/BinStatsPageParent";
import { useParams } from "react-router";
import { useGetBinByIDQuery } from "src/queries/useGetBinByIDQuery";
import RoleUtil from "src/utils/RoleUtil";
import Role from "src/consts/Role";
import { GrowerSlim, UserSessionActions } from "src/redux/actions/UserSessionActions";
import EnterpriseApiService from "src/api/EnterpriseApiService";
import { connect, useStore } from "react-redux";
import CurrentUser from "src/utils/CurrentUser";
import { StateStoreModel } from "src/redux/state/StateStoreModel";
import HistoryUtil from "src/utils/HistoryUtil";
import Routes from "src/consts/Routes";
interface GrowerSelectionProps {
    growerName: string | null;
    growerID: number | null;
    grower: GrowerSlim | null;
    growerChangeAction: (grower: GrowerSlim) => any;
    logoutAction: () => any;

}
const GrowerSelection = (props: GrowerSelectionProps) => {

    const params = useParams<any>();
    const binIdParam = params.binId;

    const binId = strictParseInt(binIdParam, 10);
    // Nan, Ids < 0, non-numeric, all evaluate to false
    const validbinIdInUrl = binId >= 0;

    const binInfoQuery = useGetBinByIDQuery(binId, { enabled: validbinIdInUrl, refetchOnReconnect: false, refetchOnWindowFocus: false });

    const [user, setUser] = useState(CurrentUser.Get());
    const currentGrowerId = props.growerID;

    const [growerIdList, setGrowerIdList] = useState<Array<GrowerSlim>>([]);

    const currentGrower = growerIdList?.find(grower => {
        return grower.growerName === props.growerName;
    });

    const currentGrowerName = currentGrower?.growerName;


    useEffect(() => {
        if (validbinIdInUrl && binInfoQuery.isInitialLoading) {
            return;
        }
        getData();
    }, [user, binInfoQuery.isInitialLoading, validbinIdInUrl]);

    const getData = () => {
        if (user?.userId) {
            if (RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN])) {
                EnterpriseApiService.getGrowerIDs(true, true).then((growerIDs) => {
                    //let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);
                    let GrowerNameIDPairs: GrowerSlim[] = growerIDs.map((grower) => {
                        const growerSlim: GrowerSlim = {
                            growerId: grower.growerID,
                            externalId: grower.externalId,
                            isExternal: grower.isExternal,
                            growerName: grower.growerName as string,
                        }
                        return growerSlim;
                    })

                    const growerNameAlreadySet = !!props.growerName;
                    if (!growerNameAlreadySet) {
                        props.growerChangeAction(GrowerNameIDPairs[0]);
                    }
                    setGrowerIdList(GrowerNameIDPairs);
                }).catch(error => {
                    notification.error({
                        message: error.message,
                        description: error.description
                    });
                });
            } else {
                EnterpriseApiService.getGrowersOfUserByUsername(user.userName!).then((growerIDs) => {
                    //let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);

                    let GrowerNameIDPairs: GrowerSlim[] = growerIDs.map((grower) => {
                        const growerSlim: GrowerSlim = {
                            growerId: grower.growerID,
                            externalId: grower.externalId,
                            isExternal: grower.isExternal,
                            growerName: grower.growerName as string,
                        }
                        return growerSlim;
                    });
                    const growerNameAlreadySet = !!props.growerName;
                    if (!growerNameAlreadySet) {
                        props.growerChangeAction(GrowerNameIDPairs[0]);
                    }
                    setGrowerIdList(GrowerNameIDPairs);
                }).catch(error => {
                    notification.error({
                        message: error.message,
                        description: error.description
                    });
                });
            }
        }
    }

    return <>
        {growerIdList.length > 0 &&
            <Col>
                {growerIdList.length > 1 ?
                    <Select showSearch optionFilterProp='children' key="select" style={{ width: 300, marginRight: '16px' }} labelInValue={true}
                        value={currentGrower?.growerName ?
                            { value: currentGrower?.growerName, label: currentGrowerName } : null}
                        onChange={(val) => {
                            const grower = growerIdList.find(grower => grower.growerName == val.value);
                            props.growerChangeAction(grower!);
                            HistoryUtil.push(Routes.HOME_ROUTE);
                        }}>
                        {growerIdList.map((grower) => {
                            return <Select.Option key={`${grower.growerName}-${grower.growerId}-${grower.externalId}`} value={grower.growerName}>{grower.growerName}</Select.Option>;
                        })}
                    </Select>
                    : null
                    // <span style={{
                    //     borderRadius: '3px', marginRight: '16px', background: 'rgba(150, 150, 150, 0.1)',
                    //     border: '1px solid rgba(100, 100, 100, 0.2)', padding: '7px'
                    // }}>
                    //     <Text>{growerIdList[0].growerName}</Text>
                    // </span>
                }
            </Col>
        }
    </>;

};

function mapDispatchToProps(dispatch: any) {
    return {
        growerChangeAction: (grower: GrowerSlim) => dispatch(UserSessionActions.changeGrower(grower)),
        logoutAction: () => dispatch(UserSessionActions.logout())
    };
}

function mapStateToProps(state: StateStoreModel) {
    return {
        growerID: state.UserSession.GrowerID,
        growerName: state.UserSession.GrowerName,
        grower: state.UserSession.grower,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GrowerSelection);
