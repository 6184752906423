import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Col, Row, Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { PRIMARY_BLUE_SHIVVERS } from "src/app/theme";
import Routes from "src/consts/Routes";
import { binDBKeys } from "src/pages/binOverview/BinCommander";
import { useBinInfoContext } from "src/queries/BinInfoContext"
import { Overview } from "./Overview";
import { IpTag, ShivversTag } from "./ShivversTag";
import ShivversService from "src/api/ShivversService";

export const DashboardCardShivversPremier = () => {
    const binInfo = useBinInfoContext();
    const history = useHistory();
    const location = useLocation();
    const queryClient = useQueryClient();

    const lastReadingQuery = useQuery({
        queryKey: [binDBKeys.moistureDisplay(binInfo.linkIpAddress!), {last: 1}],
        queryFn: async (q) => await ShivversService.lastReadings(binInfo.linkIpAddress!, 1, q.signal),
        refetchOnWindowFocus: false,
        staleTime: 1000,
    })

    const lastTapeReading = lastReadingQuery.data?.[0]?.dateinUtc != null ? dayjs(lastReadingQuery.data?.[0]?.dateinUtc).format('MM/DD/YYYY h:mm a') : '----';

    const lastTapeReadingFormatted = 'Last Tape Reading: ' + lastTapeReading;

    return <>
                       <Card key="card"
                       loading={lastReadingQuery.isFetching}
                        title={<Row justify="start" align="middle">
                            <Col>
                                {binInfo.name}
                            </Col>
                            <Col style={{ paddingLeft: 8 }}>
                                <ShivversTag />
                                <IpTag ip={binInfo?.linkIpAddress} />
                                </Col>
                        </Row>}
                        headStyle={{ background: '#939393', color: '#000000' }}

                        extra={<Row gutter={8} justify="end">
                            <Col>
                                <Button
                                    ghost={false}
                                    type="primary"
                                    onClick={() => {
                                        queryClient.setQueriesData(binDBKeys.shivversBinInfo(binInfo?.linkIpAddress!), (oldData: any) => {
                                            if (binInfo?.linkIpAddress === undefined) {
                                                return oldData;
                                            }

                                            return binInfo;
                                        });
                                        const binStatsRoute = `${Routes.BIN_STATS}/${binInfo.linkIpAddress?.replaceAll(".", "-")}`;

                                        history.push(binStatsRoute, {
                                            binInfo: binInfo,
                                        })
                                    }}>
                                    Details
                                </Button>
                            </Col>
                        </Row>
                        }>
                            <Row justify={"center"} align="middle">
                                <Col span={"400px"}>
                                    <Overview title={lastTapeReadingFormatted} layout="horizontal" columns={1} />
                                </Col>
                            </Row>
                        </Card>
    </>;
}