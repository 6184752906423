// Service TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
import BaseApi from './BaseApi';
import LoginDTO from '../models/LoginDTO';
import ResetPasswordRequestModel from '../models/ResetPasswordRequestModel';
import ChangePasswordRequestModel from '../models/ChangePasswordRequestModel';
import UserSecurityDTO from '../models/UserSecurityDTO';

export class AccountApiService extends BaseApi {

    // post: api/Account/Login
    public login(vm: LoginDTO, signal?: AbortSignal): Promise<void> {
        let url = `api/Account/Login`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(vm, 'post', url, true, false, _signal);
    }

    // get: api/Account/Logout
    public logout(signal?: AbortSignal): Promise<void> {
        let url = `api/Account/Logout`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'get', url, true, false, _signal);
    }

    // get: api/Account/GetCurrentUser
    public getCurrentUser(signal?: AbortSignal): Promise<UserSecurityDTO> {
        let url = `api/Account/GetCurrentUser`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, UserSecurityDTO>(null, 'get', url, true, false, _signal);
    }

    // post: api/Account/RequestResetPassword
    public requestResetPassword(model: ResetPasswordRequestModel, signal?: AbortSignal): Promise<void> {
        let url = `api/Account/RequestResetPassword`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(model, 'post', url, true, false, _signal);
    }

    // post: api/Account/CanResetPassword
    public canResetPassword(model: ResetPasswordRequestModel, signal?: AbortSignal): Promise<void> {
        let url = `api/Account/CanResetPassword`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(model, 'post', url, true, false, _signal);
    }

    // post: api/Account/ResetPassword
    public resetPassword(model: ResetPasswordRequestModel, signal?: AbortSignal): Promise<void> {
        let url = `api/Account/ResetPassword`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(model, 'post', url, true, false, _signal);
    }

    // post: api/Account/ChangePasswordAdminOverride?userId=${userId}&newPasswordDTO=${encodeURIComponent(newPasswordDTO)}
    public changePasswordAdminOverride(userId: number, newPasswordDTO: string, signal?: AbortSignal): Promise<void> {
        let url = `api/Account/ChangePasswordAdminOverride`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }
        if (newPasswordDTO != null) {
            url += `${prefix}newPasswordDTO=${encodeURIComponent(newPasswordDTO)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'post', url, true, false, _signal);
    }

    // post: api/Account/ChangePassword
    public changePassword(model: ChangePasswordRequestModel, signal?: AbortSignal): Promise<void> {
        let url = `api/Account/ChangePassword`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(model, 'post', url, true, false, _signal);
    }

    // post: api/Account/LockUser?userId=${userId}
    public lockUser(userId: number, signal?: AbortSignal): Promise<void> {
        let url = `api/Account/LockUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'post', url, true, false, _signal);
    }

    // post: api/Account/UnlockUser?userId=${userId}
    public unlockUser(userId: number, signal?: AbortSignal): Promise<void> {
        let url = `api/Account/UnlockUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'post', url, true, false, _signal);
    }

    // post: api/Account/DeleteUser?userId=${userId}
    public deleteUser(userId: number, signal?: AbortSignal): Promise<void> {
        let url = `api/Account/DeleteUser`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (userId != null) {
            url += `${prefix}userId=${userId}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, void>(null, 'post', url, true, false, _signal);
    }

    // get: api/Account/Ping
    public ping(signal?: AbortSignal): Promise<string> {
        let url = `api/Account/Ping`;

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, string>(null, 'get', url, true, false, _signal);
    }

    // get: api/Account/GetUserByIp?ip=${encodeURIComponent(ip)}
    public getUserByIp(ip: string, signal?: AbortSignal): Promise<any> {
        let url = `api/Account/GetUserByIp`;
        let prefix = url.indexOf('?') > 0 ? '&' : '?';
        if (ip != null) {
            url += `${prefix}ip=${encodeURIComponent(ip)}`;
            prefix = '&';
        }

        url = url.replace(/null|undefined/gi, '');
        const _signal = signal ?? new AbortController().signal;
        return this.SendRequest<any, any>(null, 'get', url, true, false, _signal);
    }
}
var service = new AccountApiService();
export default service;
