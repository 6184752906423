import dayjs from "dayjs";
import { Duration } from "dayjs/plugin/duration";
import { bind, inRange } from "lodash-es";
import React, { useMemo } from "react";
import BinDTO from "src/models/BinDTO";
import { AdditionalWeatherConditionDerived, isAtLeastOneLayerBelowDewpoint } from "src/pages/features/WeatherMonitorAntdOnly";

export const BinDTOContext = React.createContext<BinDTO | null | undefined>(undefined);

export const grainHeightOverridesPresent = (binDTO: BinDTO): boolean => {
  const overrides = binDTO?.desiredProperties?.overrides;
  const grainHeightOverridesPresent = overrides?.grainHeightAtMiddle != null || overrides?.grainHeightAtPeak != null;
  return grainHeightOverridesPresent;
}

export const useBinDTOContext = () => {
  const binDTO = React.useContext(BinDTOContext)

  const hasInnerCo2Sensor = () => {
    if (binDTO?.innerCO2 == null) {
      return false;
    }
    return true;
  }
  const hasOuterCo2Sensor = () => {
    if (binDTO?.outerCO2 == null) {
      return false;
    }
    return true;
  }

  const hasCo2Sensors = () => {
    if (!hasInnerCo2Sensor() && !hasOuterCo2Sensor()) {
      return false;
    }
    return true;
  }

  const hasRangeFinder = (): boolean => {
    return (binDTO?.innerSonar != null || binDTO?.outerSonar != null)
  }

  const computeAdditionalWeatherInfoFn = () => {
    if (binDTO?.weatherMonitorState == null) {
      return null;
    }
    return computeWeatherConditionInfo(binDTO);
  }

  const returnValue = useMemo(() => {
    return {
      binDTO, hasInnerCo2Sensor, hasOuterCo2Sensor, hasCo2Sensors,
      hasRangeFinder,
      additionalWeatherInfo: computeAdditionalWeatherInfoFn(),
    }
  }, [binDTO]);

  return returnValue;
};
export const weatherConditionEvaluation = (binDTO: BinDTO): WeatherConditionRangeStatus => {
  if (binDTO.weatherMonitorState == null) {
    return WeatherConditionRangeStatus.Unknown;
  }
  const weatherMonitorState = binDTO.weatherMonitorState;

  if (weatherMonitorState?.areWeatherConditionsWithinLimits && weatherMonitorState.timeWeatherConditionsExitedLimits == null) {
    return WeatherConditionRangeStatus.FullyIn;
  }

  if (weatherMonitorState.areWeatherConditionsWithinLimits && weatherMonitorState.timeWeatherConditionsExitedLimits) {
    return WeatherConditionRangeStatus.Exitting;
  }
  if (!weatherMonitorState.areWeatherConditionsWithinLimits && weatherMonitorState.timeWeatherConditionsEnteredLimits) {
    return WeatherConditionRangeStatus.Entering;
  }
  if (!weatherMonitorState.areWeatherConditionsWithinLimits && weatherMonitorState.timeWeatherConditionsEnteredLimits == null) {
    return WeatherConditionRangeStatus.Outside;
  }

  return WeatherConditionRangeStatus.Unknown;
};

export enum WeatherConditionRangeStatus {
  FullyIn,
  Outside,
  Exitting,
  Entering,
  Unknown
}

export const computeWeatherConditionInfo = (binDTO: BinDTO): AdditionalWeatherConditionDerived | null => {

  if (binDTO?.weatherMonitorState == null) {
    return null;
  }


  let inConditionsLong = false;
  if (binDTO?.weatherMonitorState?.areWeatherConditionsWithinLimits) {
    inConditionsLong = true;
  }
  else {
    inConditionsLong = false;
  }

  const currentWeatherConditions = binDTO.weatherMonitorState?.currentWeatherConditions;
  const weatherSettings = binDTO.weatherMonitorState;
  const heaterOffsetUsedInConditions = binDTO.heaterOffsetShouldApplyNext;

  let emcInRange: boolean | null = null;
  let temperatureInRange: boolean | null = null;
  if (weatherSettings != null) {
    if (currentWeatherConditions?.mc != null) {
      let emcMaxRange = weatherSettings.maxMcLimit;
      if (heaterOffsetUsedInConditions) {
        emcMaxRange = emcMaxRange + binDTO.weatherMonitorState.heaterPlenumMcOffset;
      }
      emcInRange = inRange(currentWeatherConditions?.mc, weatherSettings?.minMcLimit, emcMaxRange);
    }
    if (currentWeatherConditions?.temp != null) {
      temperatureInRange = inRange(currentWeatherConditions?.temp, weatherSettings?.minTemperatureLimitF, weatherSettings?.maxTemperatureLimitF);
    }

  }

  let inRangeInstant = null;
  if (emcInRange != null && temperatureInRange != null) {
    inRangeInstant = emcInRange && temperatureInRange;
  }

  let grainLayerAtRiskOfCondensation: boolean | null = isAtLeastOneLayerBelowDewpoint(binDTO);

  let resumeDuration: Duration | null = null;

  if (binDTO.weatherMonitorState?.timeWeatherConditionsEnteredLimits) {
    const date = dayjs(binDTO.weatherMonitorState.timeWeatherConditionsEnteredLimits);
    if (date.isValid()) {
      const diff = dayjs(date).add(dayjs.duration(binDTO.weatherMonitorState.timeInLimitsNeededSeconds ?? 0, 'seconds')).diff(binDTO.captureTimeUtc);
      resumeDuration = dayjs.duration(diff);
    }
  }
  if (binDTO.weatherMonitorState?.timeWeatherConditionsExitedLimits) {
    const date = dayjs(binDTO.weatherMonitorState.timeWeatherConditionsExitedLimits);
    if (date.isValid()) {
      const diff = dayjs(date).add(dayjs.duration(binDTO.weatherMonitorState.timeInLimitsNeededSeconds ?? 0, 'seconds')).diff(binDTO.captureTimeUtc);
      resumeDuration = dayjs.duration(diff);
    }
  }

  const weatherConditionRangeResult = weatherConditionEvaluation(binDTO)

  const vals: AdditionalWeatherConditionDerived = {
    inRangeLong: inConditionsLong,
    inRangeInstant: inRangeInstant,
    emcInRange: emcInRange,
    temperatureInRange: temperatureInRange,
    grainLayerAtRiskOfCondensation: grainLayerAtRiskOfCondensation,
    heaterOffsetApplied: heaterOffsetUsedInConditions,
    timeTillPhaseChange: resumeDuration,
    weatherConditionRangeStatus: weatherConditionRangeResult,
  };
  return vals;
};
