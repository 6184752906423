import { generatePath } from 'react-router-dom';
import * as queryString from 'query-string';

export enum UserSource {
    Internal = "internal",
    MyShivvers = "myshivvers",
}
class Routes {
    public static BASE_ROUTE = '';

    public static HOME_ROUTE = Routes.BASE_ROUTE + '/';
    public static LOGIN = Routes.BASE_ROUTE + '/login';
    public static PROFILE = Routes.BASE_ROUTE + '/profile';
    public static SETTINGS = Routes.BASE_ROUTE + '/settings';
    public static REQUEST_RESET_PASSWORD = Routes.BASE_ROUTE + '/reset-password';
    public static RESET_PASSWORD_LINK = Routes.BASE_ROUTE + '/reset-password-link';
    public static DASHBOARD = Routes.BASE_ROUTE + '/dashboard';
    public static ADMIN = Routes.BASE_ROUTE + '/admin';
    public static SITE_DETAIL = Routes.BASE_ROUTE + '/site-detail';
    public static BIN_STATS = Routes.BASE_ROUTE + '/bin-stats';
    public static UNAUTHORIZED = Routes.BASE_ROUTE + '/unauthorized';
    public static BINVISUAL_TEST_PAGE = Routes.BASE_ROUTE + '/bin-test';
    public static SECURITY_BASE = Routes.BASE_ROUTE + '/security';
    public static SECURITY_USERS = Routes.SECURITY_BASE + '/users';
    public static SECURITY_USER_DETAIL = Routes.SECURITY_USERS + '/:id';
    public static SECURITY_ROLEGROUPS = Routes.SECURITY_BASE + '/roleGroups';
    public static SECURITY_ROLEGROUP_DETAIL = Routes.SECURITY_ROLEGROUPS + '/:id';
    public static BIN_OVERVIEW = Routes.BASE_ROUTE + '/binOverview';
    public static REPORT_VIEWER = Routes.BASE_ROUTE + '/ReportView';

    public static REPORTS_BASE = Routes.BASE_ROUTE + '/reports';
    public static REPORTS_SQLREPORT = Routes.REPORTS_BASE + '/sqlreport/:id';

    public static USERS_BASE = Routes.BASE_ROUTE + '/users';
    // public static USERS_DETAIL = Routes.REPORTS_BASE + '/users/:id';
    public static USERS_DETAIL = Routes.BASE_ROUTE + '/users/:id';
    public static USERS_INTERNAL = Routes.generate(Routes.USERS_BASE, {
        ["userSource"]: UserSource.Internal,
    });
    public static USERS_MYSHIVVERS = Routes.generate(Routes.USERS_BASE, {
        ["userSource"]: UserSource.MyShivvers,
    });

    /**
     * Generated a url from a route and parameters.
     * @param route Route that may contain parameter placeholders.
     * @param params Object where property names equal the parameter placeholders in the route an the property value is what will be injected.
     */
    public static generate(
        route: string,
        params?: { [paramName: string]: string | number | boolean },
        query?: { [name: string]: any }) {

        let path = generatePath(route, params);

        // Add any query string variables to the route if passed
        if (query) {
            let q = queryString.stringify(query);
            if (q) {
                path += `?${q}`;
            }
        }

        return path;
    }
}

export default Routes;
