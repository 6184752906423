import { useQuery } from "@tanstack/react-query";
import ShivversService from "src/api/ShivversService";
import { ExtendedColumnType, useColumnExpansion } from "../binOverview/BinCommander";
import AdminUserSearchDTO from "src/models/AdminUserSearchDTO";
import { Col, Input, Row, Space, Table, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useLocale } from "antd/es/locale";
import Routes from "src/consts/Routes";
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { DEBOUNCE_TIME_MS } from "./UserList";
import dayjs from "dayjs";


export const PremierUserList = () => {
    const [nameSearch, setNameSearch] = useState("");
    const nameSearchDelayedValue = useDebouncedCallback((value) => {
        setNameSearch(value);
    }, DEBOUNCE_TIME_MS);

    const query = useQuery({
        queryKey: ["userSearch"],
        queryFn: async (q) => {
            return await ShivversService.searchUsers(undefined, q.signal);
        }
    });
    const history = useHistory();
    const location = useLocation();

    const columns: Array<ExtendedColumnType<AdminUserSearchDTO>> = useMemo(() => [
        {
            title: "Account",
            dataIndex: "account",
            sortType: 'string',
        },
        {
            title: "Last_Name",
            dataIndex: "lastName",
            defaultSortOrder: "ascend",
            sortType: 'string',
            filterSearch: true,
            render(value, record, index) {
                return <div>
                    <Typography.Link href={`${Routes.BIN_STATS}/${record['account']?.replaceAll(".", '-')}`} target="_blank">{value}</Typography.Link>
                </div>
            },
        },
        {
            title: "First_Name",
            dataIndex: "firstName",
            sortType: 'string',
        },
        {
            title: "Address",
            dataIndex: "address",
            sortType: 'string',
        },
        {
            title: "City",
            dataIndex: "city",
            sortType: 'string',
        },
        {
            title: 'State',
            dataIndex: 'state',
            sortType: 'string',
        },
        {
            title: 'Dealer',
            dataIndex: 'dealer',
            sortType: 'string',
        },
        {
            title: "DSM",
            dataIndex: "regionId",
            sortType: "string",
            render(value, record, index) {
                if (value === 1100) {
                    return "1100 Glen Muench";
                }
                else if (value === 1700) {
                    return "1700 Mark Sexton";
                }
                else if (value === 2100) {
                    return "2100 Kyle Canady";
                }
                else if (value === 2900) {
                    return "2900 Scott Graham";
                }
                else if (value === 3000) {
                    return "3000 Tom Barron";
                }
                else if (value === 3300) {
                    return "3300 BJ Arbuckle";
                }
                else {
                    return value;
                }
            },
        },
        {
            title: 'Premier',
            dataIndex: 'premier',
            sortType: 'string',
        },
        {
            title: 'Activation_Date',
            dataIndex: 'activationDate',
        },

    ], [query.data]);






    let transformed = useMemo(() => useColumnExpansion({ columns: columns, datasource: query.data ?? [] }), [query.data, columns]);
    const updateFilters = () => {
        if (nameSearch === "") {
            return query.data ?? [];
        }

        const filteredTable = query.data
            ?.filter((record) => record.lastName?.toUpperCase().includes(nameSearch.toUpperCase())
            || record.firstName?.toUpperCase().includes(nameSearch.toUpperCase()))
            ?? [];
        return filteredTable;
    };

    const finalTable = useMemo(() => updateFilters(), [nameSearch, query.data]);

    return <>
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Space wrap direction="horizontal" size="middle">
                    <Input style={{ width: 240 }} onChange={(evt) => nameSearchDelayedValue(evt.target.value)} placeholder='Search by Name'></Input>
                </Space>
            </Col>
            <Col xs={24}>
                <Table onRow={(data, index) => {
                    return {
                        onClick: () => {
                            const binStatsRoute = `${Routes.BIN_STATS}/${data['account']?.replaceAll(".", '-')}`;

                            history.push(binStatsRoute);
                        }
                    }
                }}
                    size="small" rowKey="internalId" pagination={{ defaultPageSize: 200 }} loading={query.isLoading} dataSource={finalTable ?? []} columns={transformed}

                />
            </Col>
        </Row>

    </>;
}