import * as React from 'react';
import { useState, useEffect } from 'react';
import { Modal, Form, Switch, Input, InputNumber, Select, FormInstance, Spin, notification, Button } from 'antd';
import RhSensorEnum from 'src/consts/RhSensorEnum';
import TemperatureSensorEnum from 'src/consts/TemperatureSensorEnum';
import BinSettingsDTO  from "src/models/BinSettingsDTO";
import BinDTO  from "src/models/BinDTO";
import  BinApiService  from 'src/api/BinApiService';

export interface BinSettingsModalProps  {

    settingsModalVisible: boolean;
    showModal(): void;
    closeModal(): void;
    bin: BinDTO | undefined;
}

const PLENUM_HIGH_TEMP_DEFAULT = 105;

export const BinSettingsModal: React.FC<BinSettingsModalProps> = ({settingsModalVisible, showModal, closeModal, bin}) => {

    
    const [_formRef] = Form.useForm<BinSettingsDTO>();
    const [loading, setLoading ] = useState<boolean>(false);

    const getRhSensorTypes = () => {

        const types : {
            label: RhSensorEnum;
            value: RhSensorEnum;
        }[] = []

        if(bin?.opiMoistureCables?.length ?? 0 > 0){
            types.push({
                label: RhSensorEnum.Opi,
                value: RhSensorEnum.Opi,
            })
        }

        if(bin?.binSenseMoistureCables?.length ?? 0 > 0){
            types.push({
                label: RhSensorEnum.BinSense,
                value: RhSensorEnum.BinSense,
            })
        }

        if(bin?.moistureCables_RF?.length ?? 0 > 0){
            types.push({
                label: RhSensorEnum.PowerCast,
                value: RhSensorEnum.PowerCast,
            })
        }

        return types
    }

    const getTempSensorTypes = () => {

        const types : {
            label: TemperatureSensorEnum;
            value: TemperatureSensorEnum;
        }[] = []

        if(bin?.opiMoistureCables?.length ?? 0 > 0){
            types.push({
                label: TemperatureSensorEnum.Opi,
                value: TemperatureSensorEnum.Opi,
            })
        }

        if(bin?.binSenseMoistureCables?.length ?? 0 > 0){
            types.push({
                label: TemperatureSensorEnum.BinSense,
                value: TemperatureSensorEnum.BinSense,
            })
        }

        if(bin?.moistureCables_RF?.length ?? 0 > 0){
            types.push({
                label: TemperatureSensorEnum.PowerCast,
                value: TemperatureSensorEnum.PowerCast,
            })
        }

        if(bin?.temperatureCables?.length ?? 0 > 0){
            types.push({
                label: TemperatureSensorEnum.Thermocouple,
                value: TemperatureSensorEnum.Thermocouple,
            })
        }

        return types
    }

    const binRhSensorTypes = getRhSensorTypes();
    const binTempSensorTypes = getTempSensorTypes();

    useEffect(() => {
        if (bin && bin.binSettings && !_formRef.isFieldsTouched()) {

            _formRef.setFieldsValue(bin.binSettings as any);
        } else {
            console.log("bin or bin.binSettings is null");
        }
    }, [bin]);


    const onFinish = async (values: BinSettingsDTO) =>{

        setLoading(true);
        try{

            values.binID = bin?.id!;   
            const result = await BinApiService.setBinSettingsOverrides(bin?.name ?? "", values);
            notification.success({ message:"Bin settings uploaded" })

        }catch(err){
            console.log("Error uploading bin settings", err);
            notification.error({
              message: err.detail,
              description: err.errorDetails.detail,
            });
        }
        
        setLoading(false);
        closeModal();
    }

    return (

        <Modal
        title="Runtime Settings"
        open={settingsModalVisible}

        onCancel={() => {
            
            _formRef.setFieldsValue(bin?.binSettings as any);
            closeModal();
        }
        }
        okButtonProps={{ style: { display: 'none' } }} // Hide OK button
        width='35%'
        >
            <Form
            scrollToFirstError={true}
            onFinish={onFinish}
            autoComplete="off"
            form={_formRef}
            >

                <Form.Item
                label="Keep Fans Off"
                name={'keepFansOff'}
                valuePropName="checked"
                >
                    <Switch/>
                </Form.Item>

                <Form.Item
                label="Keep Compressor Off"
                name={'keepCompressorOff'}
                valuePropName="checked"
                >
                    <Switch/>
                </Form.Item>

                <Form.Item
                label="Repeat PreDry"
                name={'repeatPreDry'}
                valuePropName="checked"
                extra="Repeat PreDry when entering dry mode, even if the PreDry cycle has been completed before"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                label="Plenum High Temp (°F)"
                extra={`Plenum temperature (${PLENUM_HIGH_TEMP_DEFAULT} if not set) that triggers heater shutoff and possibly fan shutoff.`}
                name={'plenumHighTempF'}
                >
                    <InputNumber min={0} style={{width: "8ch"}} />
                </Form.Item>

                <Form.Item
                label="Common-Close Time Period (Seconds)"
                extra="The amount of seconds common close solenoids must be turned on to completely close spools"
                name={'commonCloseTimePeriodSeconds'}
                >
                    <InputNumber min={0} style={{ width: '75px' }} />
                </Form.Item>

                <Form.Item
                label="Fan Stagger Seconds"
                extra="The amount of seconds each fan startup is staggered by, default 1 second"
                key={'fanStaggerSeconds'}
                name={'fanStaggerSeconds'}
                rules={[{ required: false }]}>
                    <InputNumber min={0} style={{ width: '75px' }} />
                </Form.Item>

                <Form.Item
                label="Hotspot Threshold (°F)"
                extra="Sensor temperatures above this value are detected as hotspots"
                name={'hotSpotThresholdTemperatureF'}>
                    <InputNumber style={{ width: '75px' }}  />
                </Form.Item>

                <Form.Item
                label="Weather Min MC (%)"
                name={'weatherConditionsMinMc'}
                help="System will pause if below value. Applies to FIll, Dry, and Storage"
                >
                    <InputNumber style={{ width: '75px' }} min={0} max={100} />
                </Form.Item>

                <Form.Item
                label="Weather Max MC (%)"
                name={'weatherConditionsMaxMc'}
                help="System will pause if above value. Applies to FIll, Dry, and Storage"
                >
                    <InputNumber style={{ width: '75px' }} min={0} max={100} />
                </Form.Item>

                <Form.Item
                label="Weather Min Temperature (℉)"
                name={'weatherConditionsMinTemperatureF'}
                help="System will pause if below value. Applies to FIll, Dry, and Storage"
                >
                    <InputNumber style={{ width: '75px' }} />
                </Form.Item>

                <Form.Item
                label="Weather Max Temperature (℉)"
                name={'weatherConditionsMaxTemperatureF'}
                help="System will pause if above value. Applies to FIll, Dry, and Storage"
                >
                    <InputNumber style={{ width: '75px' }} />
                </Form.Item>


                <Form.Item  
                name={"kpLayerTimeAdjustment"}
                extra="Kp constant for the auto layer time adjustment PID controller"
                label={`PID kpLayerTimeAdjustment`}>
                    <InputNumber style={{ width: '75px' }} />
                </Form.Item>
            
                <Form.Item 
                name={"kiLayerTimeAdjustment"}
                extra="Ki constant for the auto layer time adjustment PID controller"
                label={`PID kiLayerTimeAdjustment`}>
                    <InputNumber style={{ width: '75px' }} />
                </Form.Item>

                <Form.Item 
                name={"kdLayerTimeAdjustment"}
                extra="Kd constant for the auto layer time adjustment PID controller"
                label={`PID kdLayerTimeAdjustment`}>
                    <InputNumber style={{ width: '75px' }} />
                </Form.Item>

                <Form.Item  
                name={"rhSensorDataSource"}
                extra="The preferred sensor type to use for RH readings. If blank, OPI is the default"
                label={`RH preferred sensor type`}>
                    <Select style={{maxWidth: "196px"}} allowClear showSearch options={binRhSensorTypes} />
                </Form.Item>
            
                <Form.Item 
                name={"tempSensorDataSource"}
                extra="The preferred sensor type to use for temperature readings. If blank, thermocouples are the default"
                label={`Temperature preferred sensor type`}>
                    <Select style={{maxWidth: "196px"}} allowClear showSearch options={binTempSensorTypes} />
                </Form.Item>

                <Form.Item 
                name={"grainHeightAtCenterStacksFt"}
                extra="Override for setting the grain height at the center stacks of the bin"
                label={`Grain height at center stacks (ft.)`}>
                    <InputNumber style={{ width: '96px' }} />
                </Form.Item>

                <Form.Item 
                name={"grainHeightAtPerimeterStacksFt"}
                extra="Override for setting the grain height at the perimeter stacks of the bin"
                label={`Grain height at perimeter stacks (ft.)`}>
                    <InputNumber style={{ width: '96px' }} />
                </Form.Item>

                <Form.Item>
                    <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>

            <Modal
            open={loading}
            centered
            closable={false}
            footer={null}
            width={100}
            style={{ textAlign: 'center' }}
            >
              <Spin size="large" />
            </Modal>
        </Modal>
    );
}