import * as React from 'react';
import BinApiService from 'src/api/BinApiService';
import BinDTO from 'src/models/BinDTO';
import BinInfoDTO from 'src/models/BinInfoDTO';
import {
    Col,
    Row,
    Switch,
    Collapse,
    Select,
    Spin,
    Tag,
    Card,
    Popconfirm,
    message,
    Button,
    Form,
    Space,
    Modal,
    Typography,
    Popover,
    Divider,
    Skeleton,
} from 'antd';
import { withIdleTimer, IIdleTimer} from 'react-idle-timer'

// import { LikeOutlined } from '@ant-design/icons';
import { SpriteText2D, textAlign } from 'three-text2d';
import './BinVisual.less';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import StackDTO from 'src/models/StackDTO';
import ValveDTO from 'src/models/ValveDTO';
import { EditFilled, InfoCircleOutlined, MinusCircleOutlined, RedoOutlined, SettingOutlined } from '@ant-design/icons';
import ThermocoupleDTO from 'src/models/ThermocoupleDTO';
import {
    Vector3,
    Group,
    Object3D,
    WebGLRenderer,
    Scene,
    Color,
    PerspectiveCamera,
    PointLight,
    AmbientLight,
    CylinderBufferGeometry,
    MeshStandardMaterial,
    Mesh,
    SphereBufferGeometry,
    MeshBasicMaterial,
    LineBasicMaterial,
    CatmullRomCurve3,
    BufferGeometry,
    Line,
    QuadraticBezierCurve3,
    BoxBufferGeometry,

} from 'three';
import LayerDTO from 'src/models/LayerDTO';
import RoleUtil from 'src/utils/RoleUtil';
import Role from 'src/consts/Role';
import TemperatureCableDTO from 'src/models/TemperatureCableDTO';
import MoistureCableDTO from 'src/models/MoistureCableDTO';
import MoistureCableRFDTO from 'src/models/MoistureCableRFDTO';

//import RHTStateDTO from 'src/models/RHTStateDTO';
//import WirelessStateDTO from 'src/models/WirelessStateDTO';
import ValvePositionDTO from '../../../models/ValvePositionDTO';
import _ from 'lodash';
import { DeviceLogRangeForm } from './DeviceLogsRangeForm';
import { MappingErrorList, PANEL_KEYS_DEFAULT_EXPANDED, PopconfirmYesNo } from './BinStatsPage';
import ErrorPriority from 'src/consts/ErrorPriority';
import Title from 'antd/lib/typography/Title';
import BoardGroup from 'src/consts/BoardGroup';
import { LogViewer } from './LogViewer';
import { Component } from 'react'
import HeaterMode from 'src/consts/HeaterMode';
import { RequiredRule } from 'src/consts/FormConstants';
import ChangeHeaterModeDTO from 'src/models/ChangeHeaterModeDTO';
import { formatHeaterMode } from 'src/utils/formatting';
import BinVectorDTO from 'src/models/BinVectorDTO';
import { hasDyanmicLayers } from '../HomeScreen/Canvas';
import { getUserTimezoneOffset } from '../HomeScreen/BinDetails';
import { WeatherMonitorBypassModal } from './WeatherMonitorBypassModal';
import produce from 'immer';
import { FanCard } from './FanCard';
import { LogType, viewBinStateLogsByRange, viewGrainStateLogsByRange, viewRunLogsByRange } from 'src/pages/shared/downloadLogsByRange';
import FormItem from 'antd/es/form/FormItem/index';
import dayjs, { Dayjs } from 'dayjs';
import { AutomationType, selectAutomationType } from 'src/utils/deriveAutomationType';
import { RestartDriStackModuleButton } from 'src/pages/admin/RestartModuleButton';
import { formatNumber } from './HeaterControls';
import { ManualRoutineControl } from './ManualRoutineControl';
import { BinStatusCard } from './BinStatusCard';
import { getDefaultTemperatureSensorType } from 'src/pages/shared/binDTOUtils';
import TemperatureSensorEnum from 'src/consts/TemperatureSensorEnum';
import { BinSettingsModal } from './BinSettingsModal';
import { SolenoidControlModal } from './SolenoidControlModal';
import SensorActuatorControlModal from './SensorActuatorControl';


const { Option } = Select;
const { Panel } = Collapse;

export const PANEL_STYLE = {background: '#939393' };

const BINCOLOR = 0xE7E7E7;
// const STACKCOLOR = 0x9dede1;
const STACKCOLOR = 0x32d000;
const TEMPCABLECOLOR = 0xff0000;
const RHTCABLECOLOR = 0x0000ff;
const RHT_WIRELESS_CABLE_COLOR = RHTCABLECOLOR;
const GATECOLOR = 0xf0ea2b;
const DEFUALTLAYERCOLOR = 0x138d75;
export const DISABLED_TAG_COLOR = "default";
let currentAscii: string | null;

export enum OperatingMode {
    Idle = 0,
    FillBin = 1,
    PreDry = 2,
    TopDry = 31,
    Dry = 3,
    Storage = 4,
    EmptyBin = 5,
    Manual = 20,
    FailSafe = 100,
    SelfCheck = 101,
}

export const transformLayers = (binDTO: BinDTO): Array<LayerMCInfo> => {
    let layers: LayerMCInfo[] | null | undefined = binDTO?.layerGrainStates?.map((layer => ({...layer, mc: layer.moistureContent})));
    if (layers == null) {
        // use staticMC
        layers = binDTO?.layers;
    }
    return layers ?? [];

}

export const binConfiguredWithHeaters = (bin: BinDTO | null | undefined): boolean => {
    // const noHeaterOverride = bin?.desiredProperties?.overrides?.noHeater;
    const noHeaterOverride = !(bin?.hasHeaters)
    if (noHeaterOverride === null || noHeaterOverride === undefined) {
        return true;
    }
    if (noHeaterOverride === true) {
        return false;
    }
    return true;
}

const ManualModeHelpText = (<div>
    <p>This control is only changeable in User Control Mode</p>
</div>);

export const ManualModeInfoIcon = () => {
    return <>
        <Popover title={ManualModeHelpText}>
            <InfoCircleOutlined style={{ fontSize: "32px" }} />
        </Popover>
    </>
}

interface InactiveModalProps {
    userIsActive: boolean,
    onOk: () => void,
}

interface cableTransformData {
    ringLength: number;
    distanceFromCenter: number,
    isCenter: boolean,
    bearing: number,
    cableHeight: number,
}

interface RHTorSensorTextAddInputs {
    id: string | null,
    temperature: number | null,
    rh: number | null,
    mc: number | null,
    location: BinVectorDTO | null,
}

// const deviceLogsBaseURI = 'https://dristackdatalakeprod.blob.core.windows.net/device-logs/';

    const InactiveModal = (props: InactiveModalProps) => {
            return <Modal
        title="User Inactivity"
        open={!props.userIsActive}
        onOk={props.onOk}
        onCancel={props.onOk}
        footer={null}
      >
        <p>Updating bin information was paused while you were away </p>

        </Modal>;
    };


    interface ConfirmMsgProps {
        heaterMode: HeaterMode;
    }
    
    const ConfirmMsg = (props: ConfirmMsgProps) => {
        return <Typography>
    Do you still wish to change to {formatHeaterMode(props.heaterMode)} Mode? 
        </Typography>
    }
    const AmbientToOtherMode = (heaterMode: HeaterMode) => {
        return <>
        <Typography>
            Changing to this mode causes the heater to turn on. However, the system will work to not increase the overall temperature of the grain in the bin. An increase in overall temperature could result in rewetting of some layers and over drying of others. 
        </Typography>
        <ConfirmMsg heaterMode={heaterMode} />
        </>;
    }
    
    const OtherHeaterModeToAmbient = (heaterMode: HeaterMode) => {
        return <>
        <Typography>
            Grain will cool when switching to ambient, limiting the ability to switch back to a heated drying mode which, if switched back to a heated mode, could result in rewetting of some layers and over drying of others.
        </Typography>
        <ConfirmMsg heaterMode={heaterMode} />
        </>;
    }
    
    enum VariationToShow {
        NoToSome = 0,
        SomeToNo = 1,
        Same = 2,
    }
    
    const variationToShow = (currentHeaterMode: HeaterMode, desiredHeaterMode: HeaterMode): VariationToShow => {
        const someVariations = [HeaterMode.EnergyEff, HeaterMode.Speed5, HeaterMode.Speed10];
        // const noHeatVariations = [HeaterMode.Ambient];
        if (currentHeaterMode === HeaterMode.Ambient && someVariations.includes(desiredHeaterMode)) {
            return VariationToShow.NoToSome;
        }
        else if (someVariations.includes(currentHeaterMode) && desiredHeaterMode === HeaterMode.Ambient) {
            return VariationToShow.SomeToNo;
        }
        else {
            return VariationToShow.Same;
        }
    };
    

interface ChangeHeaterModeModalProps {
    deviceId: string;
    currentHeaterMode: HeaterMode,
    open: boolean,
    onOk: (desiredHeaterMode: HeaterMode) => void,
    onCancel: () => void;
}

export const ChangeHeaterModeModal = (props: ChangeHeaterModeModalProps) => {

    const [desiredMode, setDesiredMode] = React.useState(props.currentHeaterMode);
    const [pendingSubmit, setPendingSubmit] = React.useState(false);

    const handleSelectChange = (value: any) => {
        console.log("desired mode: ", value);
        setDesiredMode(value);
    }
    const onFinish = async (vals: any) => {
        try {
            setPendingSubmit(true);
        console.log("heater mode vals: ", vals);
        const heaterMode = vals.heaterMode;
        const changeHeaterModeDTO: ChangeHeaterModeDTO = {
            deviceId: props.deviceId,
            heaterMode: heaterMode,
        };
        if (await BinApiService.changeHeaterMode(changeHeaterModeDTO)) {
            message.success(`Heater mode updated to ${heaterMode}`);
        }
        else {
            message.error("Heater mode did not update");
        }
        setPendingSubmit(false);
        props.onOk(heaterMode);

        } catch (err) {
            console.log("Updating heater mode failed", err);
            message.error("An error occured while updating the heater mode");

            setPendingSubmit(false);
            props.onOk(desiredMode);
        }
    }

    const handleOk = () => {
        props.onOk(desiredMode);
    }

    const showVariation = (variation: VariationToShow, desiredHeaterMode: HeaterMode) => {
        if (variation === VariationToShow.NoToSome) {
            return AmbientToOtherMode(desiredHeaterMode);
        }
        else if (variation === VariationToShow.SomeToNo) {
            return OtherHeaterModeToAmbient(desiredHeaterMode);
        }
        else {
            return null;
            // return <ConfirmMsg heaterMode={desiredHeaterMode} />
        }
    };

    const variation = variationToShow(props.currentHeaterMode, desiredMode);


    const ContentWarningVariation = React.useMemo(() => showVariation(variation, desiredMode), [variation, desiredMode]);



    return <Modal
    title="Update Heater mode"
     open={props.open}
     onOk={handleOk}
     onCancel={props.onCancel}
     footer={[
        <Button key="back" onClick={props.onCancel} >
            Cancel
        </Button>,
        <Button key="Submit"  form="changeHeaterMode"  htmlType="submit" loading={pendingSubmit} >
            Change Mode
        </Button>,
    ]}
    
   >
                               <Form id="changeHeaterMode" onFinish={onFinish} initialValues={{ heaterMode: props.currentHeaterMode}}>

<FormItem  label="Heater Mode" name="heaterMode" rules={[RequiredRule]}>
    <Select onChange={handleSelectChange} >
        <Select.Option value={HeaterMode.Ambient}>{formatHeaterMode(HeaterMode.Ambient)}</Select.Option>
        <Select.Option value={HeaterMode.EnergyEff}>{formatHeaterMode(HeaterMode.EnergyEff)}</Select.Option>
        <Select.Option value={HeaterMode.Speed5}>{formatHeaterMode(HeaterMode.Speed5)}</Select.Option>
        <Select.Option value={HeaterMode.Speed10}>{formatHeaterMode(HeaterMode.Speed10)}</Select.Option>
    </Select>


</FormItem>
</Form>
{ContentWarningVariation}

     </Modal>;

}

export interface CompressorCardProps {
    isCompressorOn: boolean | null | undefined,
    turnOnCompressor: () => void,
    turnOffCompressor: () => void,
    operatingMode: number | null | undefined,
    hardwareYear: number,
    compressorAmps?: number | null | undefined,
    psi: number | null | undefined,
}

export const formatPSI = (psi: number | null | undefined): string => {
    if (!Number.isFinite(psi)) {
        return "";
    }
    if (psi == null) {
        return "";
    }

    return psi.toFixed(2);
}

export const formatCompressorAmps = (amps: number | null | undefined): string => {
    if (!Number.isFinite(amps)) {
        return "";
    }
    if (amps == null) {
        return "";
    }

    return amps.toFixed(2);
}

export const CompressorCard = (props: CompressorCardProps) => {
    const inManualMode = props.operatingMode === OperatingMode.Manual;

    const compressorAmpsFormatted = formatCompressorAmps(props.compressorAmps);
    const psiFormatted = formatPSI(props.psi);

    return <>
    <Card bodyStyle={{ padding: 8 }} key={"Compressor"}>
    <Row >
        <Col key={`Compressor1`} xs={6} md={3} >
            {`Compressor`}
        </Col>

        <Col key={`CompressorStatus`}>
        {/* {!inManualMode && <ManualModeInfoIcon /> } */}
            {props.isCompressorOn == null && <span>No Data</span>}
        {(props.isCompressorOn
                    ? <Popconfirm
                        title="Turn off compressor?"
                        okText="Yes"
                        cancelText="No"
                        disabled={!inManualMode}
                        onConfirm={() => props.turnOffCompressor()}>
                        <Tag style={{ textAlign: 'center' }} color={inManualMode ? "success": DISABLED_TAG_COLOR}>On</Tag>
                    </Popconfirm>
                    : <Popconfirm
                        title="Turn on Compressor?"
                        disabled={!inManualMode}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => props.turnOnCompressor()}>
                        <Tag color={inManualMode ? "error": DISABLED_TAG_COLOR}>Off</Tag>
                    </Popconfirm>
                )}
        </Col>
    </Row>
    <Row>
        <Col>
        <span>PSI: <b>{psiFormatted}</b></span>
        </Col>
    </Row>
    {props.hardwareYear >= 2022 && <Row>
        <Col>
        <span>Current: <b>{compressorAmpsFormatted}</b> Amps</span>
        </Col>
    </Row>
    }
</Card>
</>
}

export const isMoniteringBin = (binData: BinDTO | null) => {
    return binData?.desiredProperties?.overrides?.monitoringBin === true;
}

export const downloadAsciiBinState = (binState: BinDTO) => {

    var today = new Date();
    var dd = String(today.getUTCDate()).padStart(2, '0');
    var mm = String(today.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var hh = today.getHours();
    var MM = today.getMinutes();
    var ss = today.getSeconds();
    var fileName = `BinState_${binState.name}_${yyyy}${mm}${dd}_${hh}${MM}${ss}.txt`;
    // var asciiText = currentAscii ?? '';
    const element = document.createElement('a');
    const file = new Blob([binState.ascii ?? ""], { type: 'text/plain;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export const fanDurationFormatted = (fanRuntimeSeconds: number | undefined): string => {
    const maybeRuntime = fanRuntimeSeconds;
    if (maybeRuntime == null) {
        return '--';
    }
    const totalSeconds = maybeRuntime;
    const days = Math.floor(totalSeconds / 3600 / 24);
    const hours   = Math.floor(totalSeconds / 3600) % 24;
    const minutes = Math.floor(totalSeconds / 60) % 60;
    const seconds = Math.floor(totalSeconds) % 60;

    let dayFormatted = '';
    if (days >= 2) {
        dayFormatted = `${days} days, `;
    } else if (days === 1) {
        dayFormatted = `${days} day, `;
    }

    // const hhmmss =  [hours, minutes, seconds]
    //     .map(v => v < 10 ? '0' + v : v)
    //     .filter((v, i) => v !== '00' || i > 0)
    //     .join(':');
    
    const hhmm = [hours, minutes]
    .map(v => v < 10 ? '0' + v : v)
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
    return `${dayFormatted}${hhmm}`;

}


interface State {
    userIsActive: boolean,
    binInfo: BinInfoDTO;
    selectedBinId: string;
    bin?: BinDTO;
    view: 'Stack' | 'Level' | 'Debug';
    loading: boolean;
    isOpenHeaterChange: boolean;
    openWeatherMonitorBypass: boolean;
    powerCycleSelection: BoardGroup;
    pendingPowerCycle: boolean;
    refreshDelay: boolean;
    refreshCounter: number;
    valvesDisabled: boolean;
    // if the model has been initialized already
    modelInitialized: boolean;
    settingsModalVisible: boolean;
    sensorActuatorControlModalVisible: boolean;
}

export interface ObjectColorInfo {
    key: string;
    highlightColor: string;
    defaultColor: number;
    object: Mesh | SpriteText2D;
    type: string;
}
interface StatProps {
    currentStep: number;
    bin: BinDTO | null | undefined;
    binInfo: BinInfoDTO;
    activeErrors: MappingErrorList;
    isLoading(value: boolean): void;
    updateBin(bin: BinDTO): void;
    fetchingFromDevice: boolean;
}


  class IdleTimerComponent extends Component {
    render () {
      return this.props.children;
    }
  }

interface SettingsButtonProps {
    showSettingsModal(): void;
}

interface SensorActuatorControlButtonProps {
    showSensorActuatorControlModal(): void;
}

export const SettingsButton = (props: SettingsButtonProps) => {
    return (
        <Button ghost={false} type="primary" size="small" onClick ={props.showSettingsModal}>
            <SettingOutlined/>
        </Button>
    );
}

export const SensorActuatorControlButton = (props: SensorActuatorControlButtonProps) => {
    return (
        <Button ghost={false} type="primary" size="small" onClick ={props.showSensorActuatorControlModal}>
            <MinusCircleOutlined/>
        </Button>
    );
}
  const IdleTimer = withIdleTimer<any>(IdleTimerComponent);

export type LayerMCInfo = Omit<LayerDTO, "temp" | 'segments' | 'mc' | 'hasGrain'> & {mc: number | null, hasGrain: boolean | null};
class BinVisualThreeUnwrapped extends React.Component<StatProps, State> {
    private components: any;
    // private static canvas = document.getElementById('player');
    private renderer: WebGLRenderer;
    private mount: HTMLElement | null;
    private scene: Scene;
    private camera: PerspectiveCamera;
    private controls: OrbitControls;

    // private ctx: CanvasRenderingContext2D | null;
    // for the bin model views
    private gateCoverArr: Object3D[] = [];
    private arrowCurveGroupArr: Object3D[] = [];
    private ringLengths: number[] = [];
    private stackArr: Object3D[] = [];
    private stackTextArr: Object3D[] = [];
    private segmentTextArr: Object3D[] = [];
    private layerMeshArr: Object3D[] = [];
    private highlightArr: ObjectColorInfo[] = [];
    private allGatesSpools: any;
    private interval: NodeJS.Timeout;
    private idleTimer: IIdleTimer | null;
    constructor(props: StatProps) {
        super(props);



        this.allGatesSpools = {
            isGatesOpen: false,
            isSpoolsOpen: false
        };

        this.state = {
            userIsActive: true,
            binInfo: props.binInfo,
            selectedBinId: props.binInfo.deviceId || 'Lange_test',
            view: 'Level',
            loading: true,
            isOpenHeaterChange: false,
            openWeatherMonitorBypass: false,
            powerCycleSelection: BoardGroup.All,
            pendingPowerCycle: false,
            refreshDelay: false,
            refreshCounter: 0,
            valvesDisabled: false,
            modelInitialized: false,
            settingsModalVisible:false,
            sensorActuatorControlModalVisible: false
        };

        this.idleTimer = null;

        this.onPrompt = this.onPrompt.bind(this);
        this.onIdle = this.onIdle.bind(this);
        this.onAction = this.onAction.bind(this);
        this.onActive = this.onActive.bind(this);
        this.isRefetchingFromDevice = this.isRefetchingFromDevice.bind(this);
        this.handleLogClickSubmit = this.handleLogClickSubmit.bind(this);
        this.openeWeatherMonitor = this.openeWeatherMonitor.bind(this);
        this.closeWeatherMonitor = this.closeWeatherMonitor.bind(this);
        this.closeWeatherMonitorAndRefresh = this.closeWeatherMonitorAndRefresh.bind(this);
    }

    showSensorActuatorControlModal = () =>{
        this.setState({
            sensorActuatorControlModalVisible: true
        })
    }

    closeSensorActuatorControlModal = () => {
        this.setState({
            sensorActuatorControlModalVisible: false
        });
      };

    showSettingsModal = () =>{
        this.setState({
            settingsModalVisible: true
        })
    }

    closeSettingsModal = () => {
        this.setState({
            settingsModalVisible: false
        });
      };


    onPrompt  (): void {
        // Fire a Modal Prompt
      }
    
      onIdle (): void {
          this.setState({userIsActive: false});
        // Do some idle action like log out your user
      }
    
      onActive  (event: Event): void {
        this.setState({userIsActive: true});
        this.refreshBinInfoAndErrors();

        // Close Modal Prompt
        // Do some active action
      }
    
      onAction (event: Event): void {
        // Do something when a user triggers a watched event
      }


    componentDidMount() {
        this.idleTimer!.start()
        this.fetchBin(this.state.selectedBinId, true, false);

        // Not a true fix bumping to 90 seconds, but should help prevent the buildup of pending requests for data if the device is slow
        this.interval = setInterval(this.updateNumbers, 90 * 1000);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    refreshBinInfoAndErrors = () => {
        this.fetchBin(this.state.selectedBinId, false, true);
        //this.fetchErrors(false);
    }

    updateNumbers = (clearMessages: boolean = true) => {
        if (clearMessages) {
            message.destroy();
        }
        if (this.state.userIsActive) {
            this.fetchBin(this.state.selectedBinId, false, true);
            //this.fetchErrors(false);
        }
    }
    closeWeatherMonitorAndRefresh = async (bypassUntil: Dayjs | null): Promise<void> => {
        this.closeWeatherMonitor();
        if (this.props.bin?.desiredProperties?.overrides != null) {
            const updated = produce(this.props.bin, draft => {

                // draft.desiredProperties!.overrides!.weatherMonitorBypassUntil = bypassUntil;
            });
            this.props.updateBin(updated);
        }
        //this.fetchBin(this.props.binInfo.deviceId!, false, false);
    };

    closeWeatherMonitor = () => {
        this.setState({openWeatherMonitorBypass: false});
    };

    openeWeatherMonitor = () => {
        this.setState({openWeatherMonitorBypass: true});
    };

    turnonFans = () => {
        message.info(`Fans turning on. Please wait.`, 4);
        BinApiService.turnOnFans(this.state.selectedBinId).then((succeeded) => {
            if (succeeded) {
                this.setState((prev) => {
                    for (let fan of prev.bin!.fans!) {
                        fan.isOn = true;
                        var fanSprite = this.scene.getObjectByName(`Fan ${fan.id}`) as SpriteText2D;
                        fanSprite.text = ' Fan On ';
                    }
                    return prev;
                });
            }
            else {
                message.destroy();
                message.error(`One or more Fans did not turn on`);
            }
        }).catch(err => {
            message.destroy();
            message.error(`Failure Turning on Fans`);
        }
        );

    }

    turnOffFans = () => {
        message.info(`Fans turning off. Please wait.`, 4);
        BinApiService.turnOffFans(this.state.selectedBinId).then((succeeded) => {
            if (succeeded) {
                this.setState((prev) => {
                    for (let fan of prev.bin!.fans!) {
                        fan.isOn = false;
                        var fanSprite = this.scene.getObjectByName(`Fan ${fan.id}`) as SpriteText2D;
                        fanSprite.text = ' Fan Off ';
                    }
                    return prev;
                });
            }
            else {
                message.destroy();
                message.error(`One or more Fans did not turn off`);
            }
        }).catch(err => {
            message.destroy();
            message.error(`Failure Turning off Fans`);
        }
        );

    }

    turnOnFan = (fanId: string, fanIndex: number) => {
        message.info(`Fan ${fanId} turning on. Please wait.`, 4);
        BinApiService.turnOnFan(fanId, this.state.selectedBinId).then((succeeded) => {
            if (succeeded) {
                this.setState((prev) => {
                    prev.bin!.fans![fanIndex].isOn = true;
                    return prev;
                });
                var fanSprite = this.scene.getObjectByName(`Fan ${fanId}`) as SpriteText2D;
                fanSprite.text = ' Fan On ';
            }
            else {
                message.destroy();
                message.error(`Fan ${fanId} did not turn on`);
            }
        }).catch(err => {
            message.destroy();
            message.error(`Failed to turn on fan ${fanId}`);
        }
        );
    }

    turnOffFan = (fanId: string, fanIndex: number) => {
        message.info(`Fan ${fanId} turning off. Please wait.`, 4);
        BinApiService.turnOffFan(fanId, this.state.selectedBinId).then((succeeded) => {
            if (succeeded) {
                this.setState((prev) => {
                    prev.bin!.fans![fanIndex].isOn = false;
                    return prev;
                });
                var fanSprite = this.scene.getObjectByName(`Fan ${fanId}`) as SpriteText2D;
                fanSprite.text = ' Fan Off ';
        } else {
            message.destroy();
            message.error(`Fan ${fanId} did not turn off`);

        }
        }).catch(err => {
            message.destroy();
            message.error(`Failed to turn off fan ${fanId} `);
        });
    }

    turnOnCompressor = () => {
        message.info('Compressor turning on. Please wait.', 4);
        BinApiService.turnOnCompressor(this.state.selectedBinId).then((successful) => {
            if (successful) {
            this.setState((prev) => {
                prev.bin!.isCompressorOn = true;
                return prev;
            });
            message.success('Compressor turned on.');
            } else {
                message.destroy();
                message.error('Compressor did not turn on');
            }
        }).catch(err => {
            message.destroy();
            message.error('Failed to turn on compressor');
        });
    }

    turnOffCompressor = () => {
        message.info('Compressor turning off. Please wait.', 4);
        BinApiService.turnOffCompressor(this.state.selectedBinId).then((successful) => {
            if (successful) {
            this.setState((prev) => {
                prev.bin!.isCompressorOn = false;
                return prev;
            });
            message.success('Compressor turned Off.');
        } else {
            message.destroy();
            message.error('Compressor did not turn off');
        }
        }).catch(err => {
            message.error('Failed to turn off compressor');
        });
    }

    turnOnHeater = (fanId: string) => {
        message.info(`Heater for Fan ${fanId} turning on. Please wait.`, 4);
        BinApiService.turnOnHeater(this.state.selectedBinId, fanId).then((succeeded) => {
            if (succeeded) {
                this.setState((prev) => {
                    const fanIndex = prev.bin!.fans!.findIndex(fan => fan.id === fanId);
                    if (fanIndex >= 0) {
                        prev.bin!.fans![fanIndex].isHeaterOn = true;
                    }
                    return prev;
                });
                const heaterSprite = this.scene.getObjectByName(`Heater ${fanId}`) as SpriteText2D;
                heaterSprite.text = ' Heater On';
                message.success(`Heater for Fan ${fanId} turned on.`);
        } else {
            message.destroy();
            message.error(`Heater for Fan ${fanId} did not turn on`);
        }
        }).catch(err => {
            message.destroy();
            message.error(`Failed to turn on heater for Fan ${fanId}`);
        });
    }

    turnOnHeaters = () => {
        message.info('Heaters turning on. Please wait.', 4);
        let fanIds = this.state.bin!.fans!.map(x => x.id);
        BinApiService.turnOnHeaters(this.state.selectedBinId).then((succeeded) => {
            if (succeeded) {
                this.setState((prev) => {
                    prev.bin!.fans!.forEach(x => { x.isHeaterOn = true; });
                    return prev;
                });
                for (let fanId of fanIds) {
                    var heaterSprite = this.scene.getObjectByName(`Heater ${fanId}`) as SpriteText2D;
                    heaterSprite.text = ' Heater On';
                }
                message.success('Heaters turned on.');
        } else {
            message.destroy();
            message.error('Heaters did not turn on');
        }
        }).catch(err => {
            message.destroy();
            message.error('Failed to turn on heaters');
        });
    }

    turnOffHeater = (fanId: string) => {
        
        message.info(`Heater for Fan ${fanId} turning off. Please wait.`, 4);
        BinApiService.turnOffHeater(this.state.selectedBinId, fanId).then((succeeded) => {
            if (succeeded) {
                this.setState((prev) => {
                    const fanIndex = prev.bin!.fans!.findIndex(fan => fan.id === fanId);
                    if (fanIndex >= 0) {
                        prev.bin!.fans![fanIndex].isHeaterOn = false;
                    }
                    return prev;
                });
                const heaterSprite = this.scene.getObjectByName(`Heater ${fanId}`) as SpriteText2D;
                
                heaterSprite.text = ' Heater Off';
                message.success(`Heater for Fan ${fanId} turned off.`);
        } else {
            message.destroy();
            message.error(`Heater for Fan ${fanId} did not turn off`);
        }
        }).catch(err => {
            message.destroy();
            message.error(`Failed to turn off heater for Fan ${fanId}`);
        });
    }


    turnOffHeaters = () => {
        message.info('Heater turning off. Please wait.', 4);
        let fanIds = this.state.bin!.fans!.map(x => x.id);
        BinApiService.turnOffHeaters(this.state.selectedBinId).then((succeeded) => {
            if (succeeded) {
            this.setState((prev) => {
                prev.bin!.fans!.forEach(x => { x.isHeaterOn = false; });
                return prev;
            });
            for (let fanId of fanIds) {

                var heaterSprite = this.scene.getObjectByName(`Heater ${fanId}`) as SpriteText2D;
                heaterSprite.text = ' Heater Off';
            }
            message.success('Heaters turned off.');
        } else {
            message.destroy();
            message.error('Heaters did not turn off');
        }
        }).catch(err => {
            message.destroy();
            message.error('Failed to turn off heaters');
        });
    }

    onHeaterModeChangeModalOk = (desiredHeaterMode: HeaterMode) => {
        this.setState({isOpenHeaterChange: false});
        console.log("outside: desired heater mode: ", desiredHeaterMode);
        // Todo: updating the bin values swallows messages
        this.updateNumbers(false);
    }

    onHeaterModeChangeModalCancel = () => {
        this.setState({isOpenHeaterChange: false});
    }

    onChangeHeaterModeClick = () => {
        this.setState({isOpenHeaterChange: true});
    }

    handlePowerCycleSelectChange = (value: BoardGroup) => {
        this.setState({powerCycleSelection: value});
    }

    powerCycleBoardGroup = () => {
        const deviceId = this.state.binInfo.deviceId;
        if (deviceId == null) {
            message.error('Failed to powercycle board group. deviceId was empty');
            return;
        }
        const boardGroup = this.state.powerCycleSelection;
        this.setState({pendingPowerCycle: true});
        BinApiService.powerCycleBoard({deviceId, which: boardGroup })
        .then(res => {
            message.success(`Powercycled ${boardGroup} group`, 2);
        })
        .catch(err => {
            message.error(`Failed to powercycle ${boardGroup} group. ${err.description}`, 3);
        })
        .finally(() => {
            this.setState({pendingPowerCycle: false});
        });
    }

    handleInactiveUserClick = (): void => {
        this.setState({userIsActive: true});
    }

    isRefetchingFromDevice = (): boolean => {
        return this.state.refreshDelay || this.props.fetchingFromDevice;
    }

    private bypassExpired = (bypassDate: Dayjs | null): boolean => {
        if (bypassDate == null) {
            // it was never set
            return true;
        }
        if (!bypassDate.isValid()) {
            return true;
        }



        const bypassOlderThanCurrentTIme = bypassDate.diff(new Date(), 'minute', true) < 0;
        if (bypassOlderThanCurrentTIme) {
            return true;
        }
        return false;
    }

    renderRoutineInfo = (pauseMessage: string) => {
        const automationType = selectAutomationType(this.state.bin);
        if (automationType === AutomationType.DriStack) {

            if ( this.state.bin?.desiredProperties?.isSeedBin){
                return 'Current Routine: ' + `${this.state.bin?.currentRoutineName ?? "None"}` + ' Current Step: ' + `${this.state.bin?.routineEngine?.stepLabel ?? "None"}` + pauseMessage;
            }else{
                if (this.state.bin?.routineEngine?.routine == null) {
                    return "Current Routine: None";
                }
                return 'Current Routine: ' + `${this.state.bin?.routineEngine?.routine ?? "None"}` + `: ${this.state.bin?.routineEngine?.stepLabel ?? "None"}` + pauseMessage
            }
        }
        else {
            return `${pauseMessage}`;
        }
    }

    render() {
        const { bin } = this.state;
        // const bypassDate = dayjs(this.props.bin?.desiredProperties?.overrides?.weatherMonitorBypassUntil);
        // full bin cntrol
        const isAtLeastPowerUser = RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN, Role.POWERUSER]);
        const isAdmin = RoleUtil.currentUserHasAnyOfRoles([Role.ADMIN]);
        const inManaulMode = this.props.currentStep === OperatingMode.Manual;
        let notifications = '';
        let pauseMessage = (this.props.bin?.isPaused ? ' - ' + this.props.bin.reasonForPause : '');

        return (
            <>
            <IdleTimer
                ref={(ref: any) => { this.idleTimer = ref }}
                timeout={1000 * 60 * 10}
                onPrompt={this.onPrompt}
                onIdle={this.onIdle}
                onAction={this.onAction}
                onActive={this.onActive}
            >
            <InactiveModal userIsActive={this.state.userIsActive} onOk={this.handleInactiveUserClick} />
            <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                md={{ span: 24, order: 2 }}
                lg={{ span: 24, order: 1 }}
                xl={{ span: 24, order: 1 }}
                style={{ paddingBottom: 10 }}>
                <Spin spinning={this.state.loading} tip="loading..." >
                    <Card 
                        //title={this.state.bin != null ? this.renderRoutineInfo(pauseMessage) : ''}
                        title = {(this.state.bin?.name + " Overview")} 
                        // headStyle={{ background: '#939393' }}
                        extra = {
                            <Row gutter={8} justify="center" >
                                <React.Fragment>
                                    <Col>
                                        <Select size="small" style={{ width: '100%', paddingLeft: 0, fontWeight: 'bold' }}
                                                        value={this.state.view}
                                                        onClick={(event) => event.stopPropagation()}
                                                        bordered={false}
                                                        onChange={(view, options) => {
                                                            this.handleViewChange(view, bin?.desiredProperties?.ignoredSensors ?? []);
                                                        }}>
                                                            {isAtLeastPowerUser && <>
                                                                <Option value="Stack">Admin View</Option>
                                                                <Option value="Level">Grower View</Option>
                                                                <Option value="Debug">Debug View</Option>
                                                        </>}
                                                            {!isAtLeastPowerUser && <>
                                                                <Option value="Stack">Stack View</Option>
                                                                <Option value="Level">Level View</Option>
                                                        </>}
                                        </Select>
                                    </Col>

                                    <Col style={{paddingRight:"5px"}}>
                                        {isAtLeastPowerUser && <SensorActuatorControlButton showSensorActuatorControlModal={this.showSensorActuatorControlModal}/>}
                                    </Col>

                                    <Col>
                                        {isAtLeastPowerUser && <SettingsButton showSettingsModal={this.showSettingsModal}/>}
                                        
                                    </Col>
                                    
                                    <Col>
                                        <Button ghost={false}
                                                type="primary" size="small" disabled={this.isRefetchingFromDevice()} onClick={(event: any) => {
                                                        event.stopPropagation();
                                                        this.setState({ refreshCounter: 0 });
                                                        this.fetchBin(this.state.selectedBinId, false, true);
                                                    }}><RedoOutlined spin={this.isRefetchingFromDevice()} /></Button>
                                    </Col>
                                </React.Fragment>
                            </Row>
                        }>

                        <Row gutter={8}>
                                <Col xs={24} sm={24} md={24} lg={18} xl={18} style={{ paddingRight: 0 }}>
                                    <span className="notificationSection">{notifications}</span>
                                </Col>
                        </Row>
                        
                        
                        <Row gutter={8} justify="center">
                                <Col xs={24} sm={24} md={24} lg={9} xl={9} style={{ height: '100%' }}>

                                    <Col style={{ paddingLeft: 0, paddingRight: 0, overflowY: 'auto' }}>
                                        {
                                            !isAdmin && <BinStatusCard bin={this.state.bin} binInfo={this.state.binInfo} inBinVisual={true}/>
                                        }
                                        {
                                            isAtLeastPowerUser && this.state.view === 'Debug' && <div key="debug">
                                                <Switch key="stack text" onChange={pos => {
                                                    this.stackLabelVisibility(pos, bin?.desiredProperties?.ignoredSensors ?? []);
                                                }}
                                                    defaultChecked={true}
                                                />
                                                <span> Stack Text</span><br /><br />
                                                <Switch key="segment text" onChange={pos => {
                                                    this.segmentLabelVisibility(pos);
                                                }}
                                                    defaultChecked={false}
                                                />
                                                <span> Segment Text</span><br /><br />
                                                <Switch key="arrows" onChange={pos => {
                                                    this.arrowVisibility(pos);
                                                }}
                                                    defaultChecked={false}
                                                />
                                                <span>  Arrows</span><br /><br />
                                                <Switch key="layers" onChange={pos => {
                                                    this.layerMeshVisibility(pos);
                                                }}
                                                    defaultChecked={false}
                                                />
                                                <span> Layers</span><br /><br />
                                                <Switch key="Gates" onChange={pos => {
                                                    this.toggleAllGates(pos);
                                                    this.allGatesSpools.isGatesOpen = pos;
                                                }}
                                                    defaultChecked={false}
                                                />
                                                <span> Gates </span><br /><br />
                                                <Switch key="spools" onChange={pos => {
                                                    this.toggleAllSpools(pos);
                                                    this.allGatesSpools.isSpoolsOpen = pos;
                                                }}
                                                    defaultChecked={false}
                                                />
                                                <span> Spools </span><br /><br />
                                            </div>
                                        }
                                    </Col>
                                    {isAtLeastPowerUser &&
                                        <Col span={24} style={{ paddingTop: 4, paddingLeft: 0, paddingRight: 0 }}>
                                            <Button style={{ width: '100%', overflow: 'hidden' }} onClick={
                                                this.viewBinState}>Get Latest Bin State</Button>
                                        </Col>}

                                    {isAtLeastPowerUser &&
                                        <Col span={64} style={{ paddingTop: 4, paddingLeft: 0, paddingRight: 0 }}>
                                            <Collapse>
                                                <Panel header="Bin State by Date" key="1">
                                                <DeviceLogRangeForm onSubmit={this.handleLogClickSubmit('BinState')} />
                                                </Panel>
                                                <Panel header="Grain State by Date" key="2">
                                                <DeviceLogRangeForm onSubmit={this.handleLogClickSubmit('GrainState')} />
                                                </Panel>
                                                <Panel header="Run Logs by Date" key="3">
                                                <DeviceLogRangeForm onSubmit={this.handleLogClickSubmit('Run')} />
                                                </Panel>
                                            </Collapse>
                                        </Col>}

                                    {isAtLeastPowerUser &&
                                        <Col span={24} style={{ paddingTop: 4, paddingLeft: 0, paddingRight: 0 }}>
                                            <Button style={{ width: '100%', overflow: 'hidden' }} onClick={
                                                this.logAsciiBinState}>Log Ascii to Console</Button></Col>}

                                    {isAtLeastPowerUser && <Col span={24} style={{ paddingTop: 4, paddingLeft: 0, paddingRight: 0 }}>
                                            <Button style={{ width: '100%', overflow: 'hidden' }} onClick={() =>
                                                downloadAsciiBinState(bin!)}>Download Diagnostic File</Button></Col>}

                                    {/* {isAtLeastPowerUser && 
                                        <Col span={24} style={{ paddingTop: 4, paddingLeft: 0, paddingRight: 0 }}>
                                            <RestartDriStackModuleButton deviceId={this.state.binInfo?.deviceId!} buttonProps={{style: {width: "100%"}}} spaceProps={{style: {width: "100%"}}} />
                                        </Col>
                                     } */}

                                    {isAtLeastPowerUser &&
                                        <Col span={24} style={{ paddingTop: 4, paddingLeft: 0, paddingRight: 0 }}>
                                            <Button style={{ width: '100%', overflow: 'hidden' }} onClick={
                                                this.getHourlyForecast}>Log Hourly Forecast</Button></Col>}
                                    {isAtLeastPowerUser &&
                                        <Col span={24} style={{ paddingTop: 4, paddingLeft: 0, paddingRight: 0 }}>
                                            <Button style={{ width: '100%', overflow: 'hidden' }} onClick={
                                                this.getMoistureForecast}>Log Moisture Forecast</Button></Col>}
                                    <br />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={15} xl={15} style={{ paddingRight: 0 }}>
                                    <div id="player" style={{
                                        zIndex: 1,
                                        // height: window.innerHeight * .5,
                                        width: '100%',
                                        height: '100%'
                                    }} />

                                </Col>
                            </Row>
                    </Card>
                    <br/>
                    {isAtLeastPowerUser && 
                        <Card title="Troubleshooting">
                            <Row>
                                <Col span={24} style={{ paddingTop: 4, paddingLeft: 0, paddingRight: 0 }}>
                                    <Title level={4}>Select board group to powercycle</Title>
                                    <Space direction="horizontal" >
                                        <Select disabled={this.state.pendingPowerCycle} defaultValue={BoardGroup.All} onChange={this.handlePowerCycleSelectChange} style={{ width: '24ch' }}>
                                            <Option value={BoardGroup.All}>ALL</Option>
                                            <Option value={BoardGroup.Hub}>Hub</Option>
                                            <Option value={BoardGroup.Fans}>Fans</Option>
                                            <Option value={BoardGroup.StackBoards}>Stack</Option>
                                            <Option value={BoardGroup.SensingBoards}>SensorHub</Option>
                                            <Option value={BoardGroup.RHTBoards}>RHTBoards</Option>
                                            <Option value={BoardGroup.OPIBoards}>OPI</Option>
                                        </Select>
                                        <PopconfirmYesNo title={`Powercycle ${this.state.powerCycleSelection}?`} onConfirm={this.powerCycleBoardGroup}>
                                            <Button type="primary" danger loading={this.state.pendingPowerCycle}>Powercycle board group</Button>
                                        </PopconfirmYesNo>
                                    </Space>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {isAtLeastPowerUser && <LogViewer deviceId={this.state.binInfo.deviceId!} />}
                                </Col>
                            </Row>
                        </Card>
                    }
                </Spin >
            </Col >
            {
                this.state.bin && 
                    <BinSettingsModal settingsModalVisible={this.state.settingsModalVisible} showModal={this.showSettingsModal} closeModal={this.closeSettingsModal} bin={this.state.bin}/>
            }
            {
                this.state.bin && 
                    <SensorActuatorControlModal open={this.state.sensorActuatorControlModalVisible} closeModal={this.closeSensorActuatorControlModal} bin={this.state.bin}/>
            }
            </IdleTimer>
            </>
        );
    }

    heaterRecommendedTempFormatted(): string {
        const heaterMinRecomTemp = this.state.bin?.grain?.heaterMinRecomTemp;
        const heaterMaxRecomTemp = this.state.bin?.grain?.heaterMaxRecomTemp;

        if (heaterMinRecomTemp == null || heaterMaxRecomTemp == null) {
            return '-';
        }

        return `${heaterMinRecomTemp} - ${heaterMaxRecomTemp} °F`;
    }


    private populateLayer = (layer: LayerMCInfo | LayerMCInfo) => {
        var object = this.highlightArr.find(obj => obj.key === layer.number.toString());
        let index = this.highlightArr.findIndex(obj => obj.key === layer.number.toString());
        var calculatedColor = this.getBandColor(layer);
        if (object) {
            let binObject = object.object;
            let material = binObject.material;
            (material as MeshStandardMaterial).color.setHex(calculatedColor);
            this.highlightArr[index].defaultColor = calculatedColor;
        }
        let layerTxt = (this.scene.getObjectByName('Level_' + layer.number) as SpriteText2D);
        // hack for Horra demo - hide 0%
        if (this.state.bin?.name == "Horra") {
            layerTxt.text = ` L${layer.number}`;
        }
        else {
            layerTxt.text = ` L${layer.number}: ${formatNumber(layer?.mc, {decimalPlaces: 1, filler: "", suffix: "%"})}`;
        }
        // layer.segments!.forEach(segment => {
        //     let segText = (this.scene.getObjectByName(segment.id!) as SpriteText2D);
        //     segText.text = ` ${segment.moistureContent}% `;
        // });

    };

    private handleViewChange = (view: 'Stack' | 'Level' | 'Debug', ignoredIds: string[]) => {

        this.setState({ view: view });
        switch (view) {
            case 'Stack':
                this.stackLabelVisibility(true, ignoredIds);
                this.segmentLabelVisibility(false);
                this.layerMeshVisibility(false);
                break;
            case 'Level':
                this.stackLabelVisibility(true, ignoredIds);
                this.segmentLabelVisibility(false);
                this.layerMeshVisibility(true);
                break;
            case 'Debug':
                this.stackLabelVisibility(true, ignoredIds);
                this.segmentLabelVisibility(false);
                this.layerMeshVisibility(false);
                break;
            default:
                break;
        }
    }
    private initAll = () => {
        this.initModel();
        this.createTree();
        this.setState({ loading: false });
    }

    private createStack = (stack: StackDTO, stackIndex: number) => {
        const binData = this.state.bin;
        let stackClone = this.components.stackTemplate.clone();
        stackClone.material = this.components.stackTemplate.material.clone();
        stackClone.name = stack.id;
        var position;
        position = this.getStackTransformAmount(stackIndex, this.ringLengths[stack.ring], (stack.ring === 0), binData!, stack);
        var height = stack.topPoint!.heightFromFloor - .5;
        var radius = 5 / 48; // roughly .10416 this is 5 inch * .25
        stackClone.scale.set(radius, (height) * .25, radius);
        stackClone.position.set(position.x, position.y, position.z);
        this.scene.add(stackClone);
        this.addGatesToStacks(stack, stackClone, binData!);
        this.addStackText(stack, stackClone, binData!);

        // adding a little detail
        var binGeometry = new CylinderBufferGeometry(.2, .65, 1, 32, 1, true);
        var gateCoverMaterial = new MeshStandardMaterial({ color: GATECOLOR, side: THREE.FrontSide });
        var stackTopCover = new Mesh(binGeometry, gateCoverMaterial);

        var topGeometry = new SphereBufferGeometry(1, 24, 10);
        var topsphere = new Mesh(topGeometry, gateCoverMaterial);
        var sprite = new SpriteText2D(
            ` ${stack.id!} `,
            {
                align: textAlign.center,
                font: '52px Helvetica',
                fillStyle: '#FFFFFF',
                backgroundColor: '#000000',
                antialias: true
            }
        );
        sprite.scale.set(.034, .00075, .034);
        sprite.position.x = -2.9;
        sprite.position.y = .5;
        stackTopCover.scale.set(2.5, 0.04, 2.5);
        stackTopCover.position.y = .5;
        topsphere.scale.set(.2, .180, .2);
        topsphere.position.y = .5;
        stackTopCover.add(topsphere);
        stackClone.add(stackTopCover, sprite);
        this.stackArr.push(stackClone);
        var stackColor = `hsl(74, 16%, 76%)`; // hsl(0, 0%, 83%)
        this.highlightArr.push({ key: stack.id!, type: 'stack', highlightColor: stackColor, defaultColor: STACKCOLOR, object: stackClone });
    }

    private updateStacks = (bin: BinDTO) => {
        bin!.stacks?.forEach((stack: StackDTO, i: number) => {
            const curStack = this.scene.getObjectByName(stack.id!);
            if (curStack === undefined) {
                this.createStack(stack, i);
            }
            else {
                const topTemp = (curStack!.getObjectByName(`${stack.id} Temp`) as SpriteText2D);
                let topTempText = "No Temp"
                if (stack.topTemp != null) {
                    topTempText = ` ${Math.ceil(stack.topTemp).toString()}°F `;
                }
                topTemp.text = topTempText;

                let topRHText = "No RH";
                if (stack.topRH != null) {
                    topRHText = `${Math.ceil(stack.topRH).toString()}% RH`;
                }
                var topRH = (curStack!.getObjectByName(`${stack.id} RH`) as SpriteText2D);
                topRH.text = topRHText;
    
                // const { thermocouples, valves } = stack;
                // thermocouples?.forEach((thermocouple: ThermocoupleDTO) => {
                //     var thermoText = (curStack!.getObjectByName(thermocouple.id!) as SpriteText2D);
                //     thermoText.text = `${Math.ceil(thermocouple.temperature).toString()}°F`;
                // });
                stack.valves?.forEach((valve: ValveDTO) => {
                    var gateCover = curStack!.getObjectByName(valve.id!);
                    this.setGateSpoolVisuals(gateCover!, valve.isGateOpen, valve.isSpoolOpen);
                });
            }
        });
    }

    private updateLabels = () => {
        const { bin } = this.state;
        const hasFans = (this.state.bin?.fans?.length ?? 0) > 0;
        if (!isMoniteringBin(bin!)) {
            this.updateStacks(bin!);
         }

        bin!.temperatureCables!.forEach((cable: TemperatureCableDTO) => {
            var curCable = this.scene.getObjectByName(cable.id!);
            cable!.thermocouples?.forEach((thermocouple: ThermocoupleDTO) => {
                var thermoText = (curCable!.getObjectByName(thermocouple.id!) as SpriteText2D);
                var temperature = thermocouple.temperature != null ? thermocouple.temperature : 0.0;
                thermoText.text = `${Math.ceil(temperature).toString()}°F`;
            });
        });

        if (bin?.layerGrainStates) {
            bin?.layerGrainStates?.forEach(layer => {
                let convertedLayer = {...layer, mc: layer.moistureContent};
                this.populateLayer(convertedLayer)
            })
        }
        else if (hasDyanmicLayers(bin!)) {
            bin?.dynamicLayers?.forEach((layer) => {
                this.populateLayer(layer);
            });
        }
        else {
            bin?.layers?.forEach((layer) => {
                this.populateLayer(layer);
            });
        }
        if (bin?.fans != null && bin?.fans.length > 0) {
            for (let fan of bin!.fans) {
                const heaterSprite = this.scene.getObjectByName(`Heater ${fan.id}`) as SpriteText2D;
                if (heaterSprite != null) {
                    heaterSprite.text = ` Heater ${fan.isHeaterOn ? 'On' : 'Off'} `;
                }

                const fanSprite = this.scene.getObjectByName(`Fan ${fan.id}`) as SpriteText2D;
                if (fanSprite != null) {
                    fanSprite.text = ` Fan ${fan.isOn ? 'On' : 'Off'} `;
                }
            }
        }

        if ((bin?.hardwareYear ?? 0) >= 2022) {
            let co2Sprite = this.scene.getObjectByName(`CO2`) as SpriteText2D | undefined;
            if (co2Sprite == null) {
                this.addCO2Text(this.state.bin!, this.components.eaveTop);
            }
            co2Sprite = this.scene.getObjectByName(`CO2`) as SpriteText2D;
            co2Sprite.text = ` CO2: ${bin!.cO2Level ?? "___"} PPM `;
        }

        if (hasFans) {
            const plenumSprite = this.scene.getObjectByName('Plenum') as SpriteText2D;
            if (plenumSprite != null) {
                plenumSprite.text = ` Plenum ${Math.round(bin!.plenumAir!.temp!)}°F   ${Math.round(bin!.plenumAir!.rh!)}%RH`;
            }

            const ambientSprite = this.scene.getObjectByName('Ambient') as SpriteText2D;
            if (ambientSprite != null) {
                ambientSprite.text = ` Ambient ${Math.round(bin!.ambientAir!.temp!)}°F   ${Math.round(bin!.ambientAir!.rh!)}%RH `;
            }
        }

        // (hack) used to force hiding of any newly added stack text if in grower view
        this.handleViewChange(this.state.view, bin?.desiredProperties?.ignoredSensors ?? []);
    }

    private logAsciiBinState = () => {
        console.log('manual push');
        console.log(currentAscii);
    }

    private getHourlyForecast = () => {
        BinApiService.getHourlyForecast(this.state.binInfo.id).then(forecast => {
            console.log(forecast);
        }).catch(error => {
            console.log(error);
        });
    }

    private getMoistureForecast = () => {
        BinApiService.getMoistureForecast(this.state.binInfo.id).then(forecast => {
            console.log(forecast);
        }).catch(error => {
            console.log(error);
        });
    }

    // private viewLogFile(logType: string) {

    //     var deviceId = this.state.binInfo.deviceId;
    //     var today = new Date();
    //     var dd = String(today.getUTCDate()).padStart(2, '0');
    //     var mm = String(today.getUTCMonth() + 1).padStart(2, '0'); // January is 0!
    //     var yyyy = today.getFullYear();
    //     var yy = yyyy % 1000;
    //     var link: string;
    //     switch (logType) {
    //         case 'OperationLog':
    //             link = `${deviceLogsBaseURI}${deviceId}/${yyyy}-${mm}-${dd}/${mm}${dd}%20OperationLog.txt`;
    //             break;
    //         case 'CanLog':
    //             link = `${deviceLogsBaseURI}${deviceId}/${yyyy}-${mm}-${dd}/${yy}${mm}${dd}%20CanLog%20${deviceId}.txt`;
    //             break;
    //         case 'ErrorLog':
    //             link = `${deviceLogsBaseURI}${deviceId}/${yyyy}-${mm}-${dd}/${yy}${mm}${dd}%20Log%20${deviceId}.txt`;
    //             break;
    //         default:
    //             link = `${deviceLogsBaseURI}${deviceId}/${yyyy}-${mm}-${dd}/${mm}${dd}%20${logType}%20${deviceId}.txt`;
    //             break;
    //     }

    //     console.log(link);
    //     window.open(link);
    // }

    // private viewCanLog = () => {
    //     this.viewLogFile('CanLog');
    // }
    // private viewOperationLog = () => {
    //     this.viewLogFile('OperationLog');
    // }

    private viewBinState = async () => {
        var today = new Date();
        var utcToday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
        var dateStart = utcToday.toISOString().substring(0, 10); // yyyy-mm-dd   UTC
        var dateEnd = dateStart;
        await viewBinStateLogsByRange(this.state.binInfo.deviceId!, dateStart, dateEnd);
        // this.viewLogFile('BinState');
    }

    private handleLogClickSubmit = (type: LogType) => (dateStart: string, dateEnd: string) => {
        const clientFolder = this.state.bin?.desiredProperties?.binName;
        switch (type) {
            case "BinState":
                viewBinStateLogsByRange(clientFolder!, dateStart, dateEnd);
                break;
            case "GrainState":
                viewGrainStateLogsByRange(clientFolder!, dateStart, dateEnd);
                break;
            case "Run":
                viewRunLogsByRange(clientFolder!, dateStart, dateEnd);
                break;
            }
    }

    // private viewGrainStateFile = () => {
    //     this.viewLogFile('GrainState');
    // }

    // private viewErrorLog = () => {
    //     this.viewLogFile('ErrorLog');
    // }

    private fetchBin = async (deviceId: string, initialLoad: boolean, manual: boolean) => {
        const { binInfo } = this.state;
        this.setState({ refreshDelay: true });
        if (initialLoad) {
            this.props.isLoading(true);
            const userTimeZoneOffset = getUserTimezoneOffset();
            try {
                const bin = await BinApiService.getBinDetailFromAzure(deviceId, binInfo.id, userTimeZoneOffset);
                this.setState(
                    { bin, refreshDelay: false }, () => {
                        this.props.updateBin(bin);
                        this.initAll();
                    });

                this.checkForWarnings();

                currentAscii = bin.ascii;
                console.log(currentAscii);
            } catch (error) {
                this.setState({ refreshDelay: false });

                console.log(error);
            }
        } else {
            const userTimeZoneOffset = getUserTimezoneOffset();
            try {
                const bin = await BinApiService.getBinDetailFromDevice(deviceId, binInfo.id, userTimeZoneOffset);
                this.setState(
                    { bin, refreshDelay: false }, () => {
                        if (manual) {
                            message.success('Data Refreshed', 3);
                        }
                        this.props.updateBin(bin);
                        this.updateLabels();

                    });
                this.checkForWarnings();

                currentAscii = bin.ascii;
            } catch (error) {
                this.setState({ refreshDelay: false });
                console.log(error);
            }
        }
        console.log(this.state);
    }

    componentDidUpdate(prevProps: Readonly<StatProps>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.bin != this.props.bin) {
            if (this.props.bin == null) {
                console.error("not updating bin visual state since supplied binDTO is null", this.props.bin);
                return;
            }

            // prevent storing old BinDTO unless either captureTime is null
            if (this.state.bin?.captureTimeUtc != null && this.props.bin?.captureTimeUtc != null && this.state.bin?.captureTimeUtc > this.props.bin?.captureTimeUtc) {
                console.error("not updating bin visual state since supplied binDTO is older than current", "current: ", this.state.bin, "passed bin", this.props.bin);
                return;
            }

            this.setState(
                { bin: this.props.bin, refreshDelay: false }, () => {
                    if (this.state.modelInitialized) {
                        this.updateLabels();
                    }
                    else {
                        this.initAll();
                    }
                });

            //this.checkForWarnings();

            currentAscii = this.props.bin.ascii;
        }
    }


    private checkForWarnings = () => {
        // any warnings we would like to display based on bin State info

        const inModeCompressorPSIMatters: boolean = this.state.bin?.operatingMode !== OperatingMode.Idle && this.state.bin?.operatingMode !== OperatingMode.Storage && this.state.bin?.operatingMode !== OperatingMode.EmptyBin;
        const automationType = selectAutomationType(this.state.bin);
        if (automationType === AutomationType.DriStack && Number(this.state.bin?.pneumaticLinePressure) < 20 && this.state.bin?.compressorState?.isOn && inModeCompressorPSIMatters) {
            message.warning('Line Pressure low: ' + this.state.bin?.pneumaticLinePressure?.toFixed(1), 5);
            console.warn('Line Pressure low: ' + this.state.bin?.pneumaticLinePressure);
        }

        if (this.props.activeErrors == null) {
            return;
        }

        for (const err of Object.values(this.props.activeErrors)) {
            if (!err.isActive) {
                continue;
            }
            switch (err.priority) {
                case ErrorPriority.CriticalError:
                    message.error(err.name);
                    break;
                case ErrorPriority.Warning:
                    message.warning(err.name);
                    break;
                default: {
                    console.warn(`unknown err priority: ${err.name}, priority: ${err.priority}`);
                    message.error(err.name);
                }
    
        }

        }
    }

    private updateValves = (desiredValveChanges: StackDTO[]) => {
        this.setState({
            valvesDisabled: true
        });
        let valvePositionUpdates = _.flatten(desiredValveChanges!
            .map(x => x.valves!.map(y => ({ valveId: y.id, position: y.positionLabel })))) as ValvePositionDTO[];
        BinApiService.setValvePositions(this.state.binInfo.deviceId!, valvePositionUpdates).then((result: boolean) => {
            if (result === true) {
                message.success('Valve configuration changed. Watch Refresh button for update.');
            }
            else if (result === false) {
                message.error('Failed to update valve configuration in all/part. Make sure system is in User Control mode');
            }
            else {
                console.error("Unexpected valve response gotten: ", result);
                message.error("valve update failed with unknown response: contact support");
            }
        }).catch(() => {
            message.error('Failed to update valve configuration.');
        }).finally(() => {

            this.fetchBin(this.state.selectedBinId, false, true);
            
            this.setState({
                valvesDisabled: false
            });
        });
    }



    private createTree = () => {
        // this.setState({ view: 'Stack' });
        this.handleViewChange('Level', []);
    }

    // @ts-ignore defined but never used. Unknown what intended to be used for
    private highlightLevel = (key: string) => {
        let layerId = key ? key : '';
        this.setHighlightColorGroup(layerId, true);

    }

    private highlightStack = (key: string) => {
        let stackId = key ? key : ''; // if false will do nothing but reset all colors

        this.setHighlightColorGroup(stackId, true);

    }

    // @ts-ignore defined but never used. Unknown what intended to be used for
    private highlightOne = (keys: string[], type: string) => {
        for (let object of this.highlightArr) {
            let binObject = object.object;
            let material = binObject.material;
            let hasKey = keys.find(key => key === object.key);
            if (hasKey !== undefined) {
                let color = new Color(object.highlightColor);
                if (type !== 'segment') {
                    (material as MeshStandardMaterial).color.setHex(color.getHex());
                } else {
                    let hex = color.getHexString();
                    let hexString = `#${hex}`;
                    (binObject as SpriteText2D).fillStyle = hexString;
                }

            } else if (object.type === type) {
                if (type !== 'segment') {
                    (material as MeshStandardMaterial).color.setHex(object.defaultColor);
                } else {
                    let color = new Color(object.defaultColor).getHexString();
                    let colorString = `#${color}`;
                    (binObject as SpriteText2D).fillStyle = colorString;
                }
            }
        }
    }

    private setHighlightColorGroup = (key: string, resetOthers: Boolean) => {

        for (let object of this.highlightArr) {
            let binObject = object.object;
            let material = binObject.material;
            if (object.key === key) {
                let color = new Color(object.highlightColor).getHex();
                (material as MeshStandardMaterial).color.setHex(color);
            } else if (resetOthers) {
                if (object.type !== 'segment') {
                    (material as MeshStandardMaterial).color.setHex(object.defaultColor);
                } else {
                    let color = new Color(object.defaultColor).getHexString();
                    let colorString = `#${color}`;
                    (binObject as SpriteText2D).fillStyle = colorString;
                }
            }
        }
    }

    private createFans() {
        if (!this.state.bin?.fans?.length) { return; }
        let cylinderLength = .8;
        let cylinderRadius = .3;
        let cubeLength = .6;
        let fanMaterial = new MeshStandardMaterial({ color: BINCOLOR, side: THREE.FrontSide });

        for (let fan of this.state.bin!.fans!) {
            let radians = THREE.MathUtils.degToRad(fan.location!.bearingAngle);

            var cylinderGeometry = new CylinderBufferGeometry(cylinderRadius, cylinderRadius, cylinderLength, 32, 1, false);
            var cylinderMesh = new Mesh(cylinderGeometry, fanMaterial);

            var cubeGeometry = new BoxBufferGeometry(cubeLength, cubeLength, cubeLength);
            var cubeMesh = new Mesh(cubeGeometry, fanMaterial);

            cylinderGeometry.translate(0, fan.location!.distanceFromCenter * 0.25 + cylinderLength / 2, 0);
            cubeGeometry.translate(0, fan.location!.distanceFromCenter * .25 + cylinderLength + cubeLength / 2, 0);

            cylinderMesh.rotateZ(-THREE.MathUtils.degToRad(90));
            cylinderMesh.rotateOnWorldAxis(new Vector3(0, 1, 0), radians);

            cubeMesh.rotateZ(-THREE.MathUtils.degToRad(90));
            cubeMesh.rotateOnWorldAxis(new Vector3(0, 1, 0), radians);

            cylinderMesh.position.y = -this.state.bin.eaveHeight * .5 * .25 + fan.location!.heightFromFloor * .25 + cylinderRadius / 2;
            cubeMesh.position.y = -this.state.bin.eaveHeight * .5 * .25 + fan.location!.heightFromFloor * .25 + cylinderRadius / 2;

            var fanLabel = new SpriteText2D(
                ` Fan ${fan.isOn ? 'On' : 'Off'} `,
                {
                    align: textAlign.center,
                    font: '60px Helvetica',
                    fillStyle: '#FFFFFF',
                    backgroundColor: '#000000',
                    antialias: true
                });
            fanLabel.name = `Fan ${fan.id}`;
            fanLabel.scale.set(.0065, .0065, .0065);
            fanLabel.translateY(this.state.bin.diameter * .5 * .25 + cylinderLength + cubeLength + .8);
                       
            var heaterLabel = new SpriteText2D(
                ` Heater ${fan.isHeaterOn ? 'On' : 'Off'} `,
                {
                    align: textAlign.center,
                    font: '60px Helvetica',
                    fillStyle: '#FFFFFF',
                    backgroundColor: '#000000',
                    antialias: true
                });

            heaterLabel.scale.set(.0065, .0065, .0065);
            heaterLabel.name = `Heater ${fan.id}`;
            heaterLabel.translateY(this.state.bin.diameter * .5 * .25 + cylinderLength);
            heaterLabel.translateZ(1.4);

            this.scene.add(cylinderMesh);
            this.scene.add(cubeMesh);
            cubeMesh.add(fanLabel);
            if (binConfiguredWithHeaters(this.state.bin)) {
                cubeMesh.add(heaterLabel);
            }
        }
    }

    private initModel = () => {
        // Add renderer mount it onto the page
        this.renderer = new WebGLRenderer({ antialias: true });

        this.mount = document.getElementById('player');

        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.mount?.appendChild(this.renderer.domElement);
        var container = this.renderer.domElement.parentElement;
        let box = container!.getBoundingClientRect();
        this.renderer.setSize(box.width, box.height);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        window.addEventListener('resize', this.onContainerResize);
        this.scene = new Scene();
        this.scene.background = new Color('#FFFFFF');
        this.camera = new PerspectiveCamera(60, 1, .1, 2000);
        this.camera.aspect = box.width / box.height;
        this.camera.updateProjectionMatrix();
        var light = new PointLight(0xfbf9e4, .6);
        light.position.set(300, 650, 1000);
        light.castShadow = true;
        this.scene.add(light);
        var light2 = new AmbientLight(0xFFFFFF, .5);
        this.scene.add(light2);
        this.controls = new OrbitControls(this.camera, this.mount!);
        this.controls.enablePan = false;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.25;
        this.controls.minPolarAngle = Math.PI / 4;
        this.controls.maxPolarAngle = 2 * Math.PI / 3;
        // build template models of the bin
        var eaveGeometry = new CylinderBufferGeometry(0.2, 1, 1, 32, 1, true);
        var binGeometry = new CylinderBufferGeometry(1, 1, 1, 32, 1, true);
        var cableGeometry = new CylinderBufferGeometry(0.2, 0.2, 1, 32, 1, false);
        var binNonOpenFacedGeometry = new CylinderBufferGeometry(1, 1, 1, 42, 1, false);
        var gateCoverGeometry = new CylinderBufferGeometry(0.4, 0.65, 1, 42, 1, true);
        var backMaterial = new MeshStandardMaterial({ color: BINCOLOR, side: THREE.BackSide });
        var frontMaterial = new MeshStandardMaterial({ color: BINCOLOR, side: THREE.FrontSide });
        var stackMaterial = new MeshStandardMaterial({ color: STACKCOLOR, side: THREE.FrontSide });
        var cableTMaterial = new MeshStandardMaterial({ color: TEMPCABLECOLOR, side: THREE.FrontSide });
        var cableRHTMaterial = new MeshStandardMaterial({ color: RHTCABLECOLOR, side: THREE.FrontSide });
        var cableRHTWirelessMaterial = new MeshStandardMaterial({ color: RHT_WIRELESS_CABLE_COLOR, side: THREE.FrontSide });
        var gateCoverMaterial = new MeshStandardMaterial({ color: GATECOLOR, side: THREE.FrontSide });

        var bin = new Mesh(binGeometry, backMaterial);
        var clearBin = bin.clone();
        // used to position always-visible sprite text
        var alwaysVisibleClearBin = bin.clone();

        var eave = new Mesh(eaveGeometry, backMaterial);
        var bottom = new Mesh(binNonOpenFacedGeometry, frontMaterial);
        var eaveTop = new Mesh(binNonOpenFacedGeometry, frontMaterial);
        var stackTemplate = new Mesh(binGeometry, stackMaterial);
        var cableTTemplate = new Mesh(cableGeometry, cableTMaterial);
        var cableRHTTemplate = new Mesh(cableGeometry, cableRHTMaterial);
        var cableRHTWirelessTemplate = new Mesh(cableGeometry, cableRHTWirelessMaterial);

        var gateCover = new Mesh(gateCoverGeometry, gateCoverMaterial);

        this.components = {
            bin: bin,
            clearBin: clearBin,
            alwaysVisibleClearBin: alwaysVisibleClearBin,
            eave: eave,
            bottom: bottom,
            eaveTop: eaveTop,
            stackTemplate: stackTemplate,
            cableTTemplate: cableTTemplate,
            cableRHTTemplate: cableRHTTemplate,
            cableRHTWirelessTemplate: cableRHTWirelessTemplate,
            gateCover: gateCover,
        };

        // scale and transform object to bin amount
        this.adjustBinModel();
        bin.add(bottom);
        bottom.scale.set(1, 0.05, 1);
        bottom.position.y = -0.5;
        eave.add(eaveTop);
        eaveTop.scale.set(0.204, 0.04, 0.204);
        eaveTop.position.y = 0.51;

        if ((this.state.bin?.hardwareYear ?? 0) >= 2022) {
            this.addCO2Text(this.state.bin!, this.components.eaveTop);
        }
        this.scene.add(bin, clearBin, alwaysVisibleClearBin, eave);
        this.createStacks();
        this.createLayers();
        if ((this.state.bin?.fans?.length ?? 0) > 0) {
            this.createFans();
            this.addPlenumLabel(this.state.bin!);
            this.addAmbientTempLabel(this.state.bin!);
        }

        this.camera.position.set(9, 0, 9);
        this.controls.update();
        this.renderScene();
        this.animate();
        this.setState({modelInitialized: true});
        this.props.isLoading(false);

    }
    private adjustBinModel = () => {
        var binData = this.state.bin;
        var { bin, clearBin, alwaysVisibleClearBin, eave } = this.components;
        var diameter = binData!.diameter;
        var radius = diameter / 2;
        var eaveHeight = binData!.eaveHeight;
        var eaveTipHeight = binData!.peakHeight - eaveHeight;
        // scaling everything down to 1/4 scale
        bin.scale.set(radius * .25, eaveHeight * .25, radius * .25);
        bin.position.y = 0; // (eaveHeight * .25) / 2;
        clearBin.scale.set(radius * .25, eaveHeight * .25, radius * .25);
        clearBin.position.y = 0;
        clearBin.material = bin.material.clone();
        clearBin.material.transparent = true;
        clearBin.material.opacity = 0;
        clearBin.visible = false;
        alwaysVisibleClearBin.scale.set(radius * .25, eaveHeight * .25, radius * .25);
        alwaysVisibleClearBin.position.y = 0;
        alwaysVisibleClearBin.material = bin.material.clone();
        alwaysVisibleClearBin.material.transparent = true;
        alwaysVisibleClearBin.material.opacity = 0;
        alwaysVisibleClearBin.visible = true;
        eave.scale.set(radius * .25, eaveTipHeight * .25, radius * .25);
        eave.position.y = ((eaveHeight * .25) / 2) + ((eaveTipHeight * .25) / 2);

    }

    
    private addMoistureCable = (cable: MoistureCableDTO, index: number, ringLengths: number[], isWireless: boolean = false) => {
        if ((cable?.rhtStates?.length ?? 0) <= 0) {
            // skip
            return;
        }

        let cableClone = this.components.cableRHTTemplate.clone();
        cableClone.material = this.components.cableRHTTemplate.material.clone();
        cableClone.name = cable.id;
        var position;
        position = this.getMoistureCableTransformAmount(index, ringLengths[cable.ring], (cable.ring === 0), this.state.bin!, cable);
        // var height = cable.topPoint!.heightFromFloor - .5;
        var height = cable.height - .5;
        var radius = 5 / 48; // roughly .10416 this is 5 inch * .25
        cableClone.scale.set(radius, (height) * .25, radius);
        cableClone.position.set(position.x, position.y, position.z);
        this.scene.add(cableClone);
        this.addMoistureCableText(cable, cableClone, this.state.bin!);
    }

    private addMoistureRFCable = (cable: MoistureCableRFDTO, index: number, ringLengths: number[]) => {
        if ((cable?.wirelessStates?.length ?? 0) <= 0) {
            // skip
            return;
        }

        let cableClone = this.components.cableRHTWirelessTemplate.clone();
        cableClone.material = this.components.cableRHTWirelessTemplate.material.clone();
        cableClone.name = cable.id;
        const position = this.getMoistureCableRFTransformAmount(index, ringLengths[cable.ring], (cable.ring === 0), this.state.bin!, cable);
        // var height = cable.topPoint!.heightFromFloor - .5;
        var height = cable.height - .5;
        var radius = 5 / 48; // roughly .10416 this is 5 inch * .25
        cableClone.scale.set(radius, (height) * .25, radius);
        cableClone.position.set(position.x, position.y, position.z);
        this.scene.add(cableClone);
        this.addMoistureCableRFText(cable, cableClone, this.state.bin!);
    }

    private createStacks = () => {
        var binData = this.state.bin;
        var stacks = binData!.stacks;
        var tempCables = binData!.temperatureCables;
        var moistureCables = binData!.moistureCables ?? [];
        const moistureCables_RF = binData!.moistureCables_RF ?? [];
        const opiMoistureCables = binData?.opiMoistureCables ?? [];
        const binSenseMoistureCables = binData?.binSenseMoistureCables ?? [];
        var quit = false;
        var currentRing = 0;

        while (!quit) {
            if(stacks == null || stacks.length == 0){
                const numberOfRings = this.state.bin?.desiredProperties?.layout?.rings?.length ?? 0;
                for(var j = 0; j< numberOfRings; j++){
                    this.ringLengths.push(0);
                }
                quit = true;
            }else{
                const stackCount = stacks?.filter(s => s.ring === currentRing).length ?? 0;
                if (stackCount > 0) {
                    this.ringLengths.push(stackCount);
                } else {
                    quit = true;
                }
                currentRing++;
            }
        }

        tempCables?.forEach((cable: TemperatureCableDTO, i: number) => {
            let cableClone = this.components.cableTTemplate.clone();
            cableClone.material = this.components.cableTTemplate.material.clone();
            cableClone.name = cable.id;
            var position;
            position = this.getTempCableTransformAmount(i, this.ringLengths[cable.ring], (cable.ring === 0), binData!, cable);
            // var height = cable.topPoint!.heightFromFloor - .5;
            var height = cable.height - .5;
            var radius = 5 / 48; // roughly .10416 this is 5 inch * .25
            cableClone.scale.set(radius, (height) * .25, radius);
            cableClone.position.set(position.x, position.y, position.z);
            this.scene.add(cableClone);
            this.addTempCableText(cable, cableClone, binData!);
        });

        moistureCables?.forEach((cable: MoistureCableDTO, i: number) => {
            this.addMoistureCable(cable, i, this.ringLengths);
        });

        moistureCables_RF?.forEach((cable: MoistureCableRFDTO, i: number) => {
            this.addMoistureRFCable(cable, i, this.ringLengths);
        });

        opiMoistureCables?.forEach((cable: MoistureCableDTO, i: number) => {
            this.addMoistureCable(cable, i, this.ringLengths);
        });

        binSenseMoistureCables?.forEach((cable: MoistureCableDTO, i: number) => {
            this.addMoistureCable(cable, i, this.ringLengths);
        });

        if (!isMoniteringBin(binData!) && stacks != null && stacks?.length > 0) {
            stacks.forEach((stack: StackDTO, i: number) => {
                this.createStack(stack, i);
            });
        }
    }



    private createLayers = () => {
        var binData = this.state.bin;
        let layers: LayerMCInfo[] = transformLayers(binData!);
        console.log("layer info: ", layers);

        // if (hasDyanmicLayers(binData!)) {
        //     layers = binData!.dynamicLayers!;
        // }
        // else {
        //     layers = binData!.layers!;
        // }
        var eaveGeometry = new CylinderBufferGeometry(0.25, 1, 1, 32, 1, true);
        var layerGeometry = new CylinderBufferGeometry(1, 1, 1, 32, 1, true);
        var layerMaterial = new MeshStandardMaterial({ color: DEFUALTLAYERCOLOR, side: THREE.BackSide });

        layers?.forEach((layer: LayerMCInfo, i: any) => {
            let ifTop = layer.bottom!.heightFromFloor >= binData!.eaveHeight * .90;
            var calculatedColor = this.getBandColor(layer);
            var newMaterial = layerMaterial.clone();
            newMaterial.color.setHex(calculatedColor);
            var layerMesh = new Mesh(ifTop ? eaveGeometry : layerGeometry, newMaterial);
            var dimensions = this.getLayerTransformAmount(binData!, layer, ifTop);
            layerMesh.name = layer.number.toString();
            var noTopCone = !ifTop && (layer.top!.heightFromFloor >= binData!.eaveHeight);

            this.addLayerLabels(layer, binData!, layerMesh, noTopCone);
            
            layerMesh.scale.set(
                layer.bottom!.heightFromFloor >= binData!.eaveHeight ? .92 : (ifTop ? 1 : .98),
                dimensions.scaleY,
                layer.bottom!.heightFromFloor >= binData!.eaveHeight ? .92 : (ifTop ? 1 : .98));
            layerMesh.position.y = dimensions.y;
            this.components.bin.add(layerMesh);

            layerMesh.visible = false;
            this.layerMeshArr.push(layerMesh);
            this.highlightArr.push({
                key: layer.number.toString(),
                type: 'layer',
                highlightColor: 'hsl(168,60%,70%)',
                defaultColor: calculatedColor,
                object: layerMesh
            });
        });
    }

    private getBandColor = (layer: LayerMCInfo) => {
        const { bin } = this.state;
        const EMPTY_COLOR = 0xc7c7c7;
        const YELLOW_COLOR = 0xD1BE14;
        const LIGHT_BLUE = 0x15CAD1;
        const DARK_BLUE = 0x1595D1;

        if (!layer.hasGrain) {
            return EMPTY_COLOR;
        }

        var mc = layer.mc;
        if (mc == null) {
            return DEFUALTLAYERCOLOR;
        }
        if(mc < 16){
            return YELLOW_COLOR
        }
        if(mc >= 16 && mc <= 20){
            return LIGHT_BLUE;
        }
        if(mc >= 20){
            return DARK_BLUE;
        }
        return DEFUALTLAYERCOLOR;
    }

    private addLayerLabels = (layer: LayerMCInfo, binData: BinDTO, layerMesh: Object3D, noTopCone: boolean) => {
    


        // if(layer.mc === null){
        //     return;
        // }

        let layerText = ` L${layer.number}: ${formatNumber(layer?.mc, {decimalPlaces: 1, filler: "", suffix: "%"})}`;
            
        var layerLevelLabel = new SpriteText2D(
            layerText,
            {
                align: textAlign.center,
                font: '60px Helvetica',
                fillStyle: '#FFFFFF',
                backgroundColor: '#000000',
                antialias: true,
            });

        layerLevelLabel.scale.set(.0014, .0011, .0014);
        let tempVector = new Vector3();
        layerLevelLabel.getWorldScale(tempVector);
        layerLevelLabel.name = 'Level_' + layer.number;
        let ifTopMovFront = 1;
        let ifTopMovSide = -.9;
        let layerMiddle = (((noTopCone ? binData!.eaveHeight : layer.top!.heightFromFloor) - layer.bottom!.heightFromFloor) / 2);
        let labelHeight = ((layerMiddle + layer.bottom!.heightFromFloor) / binData!.eaveHeight) - .5;
        layerLevelLabel.position.set(ifTopMovSide, labelHeight, ifTopMovFront);

        if((binData.moistureCables == null || binData.moistureCables.length === 0) 
            && (binData.opiMoistureCables == null || binData.opiMoistureCables.length === 0)){
            layerLevelLabel.visible = false;
        }

        this.components.clearBin.add(layerLevelLabel);

        // layer.segments!.forEach((segment) => {
        //     var segmentSprite = new SpriteText2D(
        //         ` ${segment.moistureContent ? `${segment.moistureContent}%` : ''} `,
        //         {
        //             align: textAlign.center,
        //             font: '42px Helvetica',
        //             fillStyle: '#FFFFFF',
        //             backgroundColor: '#000000',
        //             antialias: true
        //         });
        //     var bearingAngle = segment.centerPoint!.bearingAngle;
        //     segmentSprite.name = segment.id ? segment.id : '';
        //     var distFromCenter = segment.centerPoint?.distanceFromCenter
        //              ?segment.centerPoint.distanceFromCenter / (binData.diameter * 0.5)
        //              : 0.05;
        //     var x = distFromCenter * Math.cos(bearingAngle * Math.PI / 180);
        //     var z = distFromCenter * Math.sin(bearingAngle * Math.PI / 180);
        //     var y = ((segment.centerPoint!.heightFromFloor) / binData.eaveHeight) - 0.5;
        //     segmentSprite.position.set(x, y, z);
        //     segmentSprite.scale.set(.0015, .0011, .0015);

        //     this.highlightArr.push({
        //         key: segment.id!,
        //         type: 'segment',
        //         highlightColor: 'hsl(0, 100%, 50%)',
        //         defaultColor: 0xFFFFFF,
        //         object: segmentSprite
        //     });
        //     segmentSprite.visible = false;
        //     this.components.bin.add(segmentSprite);
        //     this.segmentTextArr.push(segmentSprite);
        // });
    }

    private addPlenumLabel = (binData: BinDTO) => {
        var plenumLabel = new SpriteText2D(
            ` Plenum ${Math.round(binData.plenumAir!.temp!)}°F   ${Math.round(binData.plenumAir!.rh!)}%RH`,
            {
                align: textAlign.center,
                font: '60px Helvetica',
                fillStyle: '#FFFFFF',
                backgroundColor: '#000000',
                antialias: true
            });

        plenumLabel.scale.set(.0014, .0011, .0014);
        plenumLabel.name = 'Plenum';
        plenumLabel.position.set(0, -.5, 1.1);

        this.components.alwaysVisibleClearBin.add(plenumLabel);
    }

    private addAmbientTempLabel = (binData: BinDTO) => {
        var ambientTempLabel = new SpriteText2D(
            ` Ambient ${Math.round(binData.ambientAir!.temp!)}°F   ${Math.round(binData.ambientAir!.rh!)}%RH `,
            {
                align: textAlign.center,
                font: '60px Helvetica',
                fillStyle: '#FFFFFF',
                backgroundColor: '#000000',
                antialias: true
            });

        ambientTempLabel.scale.set(.0014, .0011, .0014);
        ambientTempLabel.name = 'Ambient';
        ambientTempLabel.position.set(1, -.5, 1.1);

        this.components.alwaysVisibleClearBin.add(ambientTempLabel);
    }

    private getLayerTransformAmount = (binData: BinDTO, layer: LayerMCInfo, ifTop: boolean) => {
        var topLongNoCone = !ifTop && (layer.top!.heightFromFloor >= binData!.eaveHeight);
        var diff = (topLongNoCone ? binData!.eaveHeight : layer.top!.heightFromFloor) - layer.bottom!.heightFromFloor;
        var topLong = ifTop && (layer.bottom!.heightFromFloor <= binData!.eaveHeight);
        var scaleY = (diff / binData.eaveHeight) * .9;
        var position = ((layer.bottom!.heightFromFloor / binData.eaveHeight) - (topLong ? .465 : .5)) + (scaleY / 2);
        return { scaleY: scaleY, y: position };

    }

    private  calculateCableTransformAmount = (transformData: cableTransformData): Vector3 => {
        const isCenter = transformData.isCenter;
        const ringLength = transformData.ringLength;
        const cableHeight = transformData.cableHeight;
        const bearing = transformData.bearing;
        const distanceFromCenter = transformData.distanceFromCenter;

        if (isCenter && ringLength === 1) {
            // var height = (((stack.topPoint!.heightFromFloor * 0.25) - (binData!.eaveHeight * 0.25)) / 2);
            var height = (((cableHeight * 0.25) - (this.state.bin!.eaveHeight * 0.25)) / 2);
            return new Vector3(0, height, 0);
        } else {

            // let x = 0, y = 0, z = 0, distanceFromCenter = stack.topPoint!.distanceFromCenter;
            //let x = 0, y = 0, z = 0;
            // let angle = 360 / ringLength;
            // let degrees = angle * i;

            // Make sure x value is never too close to zero (don't want a stack being blocked from view if we can't move the camera)
            let startingDegree = 0;
            switch (ringLength) {
                case 3:
                    startingDegree = 20;
                    break;
                case 4:
                    startingDegree = 20;
                    break;
                case 5:
                    startingDegree = 22;
                    break;
                case 6:
                    startingDegree = 20;
                    break;
                default:
                    break;
            }
            let radians = (startingDegree + bearing) * Math.PI / 180;
            const x = (Math.cos(radians) * distanceFromCenter) * 0.25;
            const z = (Math.sin(radians) * distanceFromCenter) * 0.25;
            // y = (((stack.topPoint!.heightFromFloor * 0.25) - (binData!.eaveHeight * 0.25)) / 2);
            const y = (((cableHeight * 0.25) - (this.state.bin!.eaveHeight * 0.25)) / 2);

            return new Vector3(x, y, z);
        }
    }

    private getMoistureCableTransformAmount = (i: number, ringLength: number, isCenter: boolean, binData: BinDTO, cable: MoistureCableDTO): Vector3 => {
        if (isCenter && ringLength === 1) {
            const height = (((cable.height * 0.25) - (binData!.eaveHeight * 0.25)) / 2);
            return new Vector3(0, height, 0);
        }

        const transformInputData: cableTransformData =  {
            bearing: cable.bearing,
            isCenter: isCenter,
            ringLength: ringLength,
            distanceFromCenter: cable.rhtStates![0].location!.distanceFromCenter,
            cableHeight: cable.height,
        };
        const transformAmount = this.calculateCableTransformAmount(transformInputData);
        return transformAmount;
    }

    private getMoistureCableRFTransformAmount = (i: number, ringLength: number, isCenter: boolean, binData: BinDTO, cable: MoistureCableRFDTO): Vector3 => {
        if (isCenter && ringLength === 1) {
            const height = (((cable.height * 0.25) - (binData!.eaveHeight * 0.25)) / 2);
            return new Vector3(0, height, 0);
        }

        const transformInputData: cableTransformData =  {
            bearing: cable.bearing,
            isCenter: isCenter,
            ringLength: ringLength,
            distanceFromCenter: cable.wirelessStates![0].location!.distanceFromCenter,
            cableHeight: cable.height,
        };
        const transformAmount = this.calculateCableTransformAmount(transformInputData);
        return transformAmount;
    }

    private getTempCableTransformAmount = (i: number, ringLength: number, isCenter: boolean, binData: BinDTO, cable: TemperatureCableDTO) => {
        if (isCenter && ringLength === 1) {
            // var height = (((stack.topPoint!.heightFromFloor * 0.25) - (binData!.eaveHeight * 0.25)) / 2);
            var height = (((cable.height * 0.25) - (binData!.eaveHeight * 0.25)) / 2);
            return new Vector3(0, height, 0);
        } else {

            // let x = 0, y = 0, z = 0, distanceFromCenter = stack.topPoint!.distanceFromCenter;
            var firstThermocouple = cable?.thermocouples![0];
            let x = 0, y = 0, z = 0, distanceFromCenter = firstThermocouple.location!.distanceFromCenter;
            // let angle = 360 / ringLength;
            // let degrees = angle * i;

            // Make sure x value is never too close to zero (don't want a stack being blocked from view if we can't move the camera)
            let startingDegree = 0;
            switch (ringLength) {
                case 3:
                    startingDegree = 20;
                    break;
                case 4:
                    startingDegree = 20;
                    break;
                case 5:
                    startingDegree = 22;
                    break;
                case 6:
                    startingDegree = 20;
                    break;
                default:
                    break;
            }
            let radians = (startingDegree + cable.bearing) * Math.PI / 180;
            x = (Math.cos(radians) * distanceFromCenter) * 0.25;
            z = (Math.sin(radians) * distanceFromCenter) * 0.25;
            // y = (((stack.topPoint!.heightFromFloor * 0.25) - (binData!.eaveHeight * 0.25)) / 2);
            y = (((cable.height * 0.25) - (binData!.eaveHeight * 0.25)) / 2);

            return new Vector3(x, y, z);
        }
    }

    private getStackTransformAmount = (stackIndex: number, ringLength: number, isCenter: boolean, binData: BinDTO, stack: StackDTO) => {

        if (isCenter && ringLength === 1) {
            var height = (((stack.topPoint!.heightFromFloor * 0.25) - (binData!.eaveHeight * 0.25)) / 2);
            return new Vector3(0, height, 0);
        } else {

            let x = 0, y = 0, z = 0, distanceFromCenter = stack.topPoint!.distanceFromCenter;
            // let angle = 360 / ringLength;
            // let degrees = angle * stackIndex;

            // Make sure x value is never too close to zero (don't want a stack being blocked from view if we can't move the camera)
            // let startingDegree = 0;

            // switch (ringLength) {
            //     case 3:
            //         startingDegree = 20;
            //         break;
            //     case 4:
            //         startingDegree = 20;
            //         break;
            //     case 5:
            //         startingDegree = 22;
            //         break;
            //     case 6:
            //         startingDegree = 20;
            //         break;
            //     default:
            //         break;
            // }


            let finalDegree = stack.bearing;
            // Make sure x value is never too close to zero (don't want a stack being blocked from view if we can't move the camera)
            const MIN_BEARING = 20;
            const MAX_BEARING = 340;
            if (stack.bearing >= MAX_BEARING) {
                finalDegree = MAX_BEARING;
            }
            else if (stack.bearing <= MIN_BEARING) {
                finalDegree = MIN_BEARING;
            }

            let radians = (finalDegree) * Math.PI / 180;
            x = (Math.cos(radians) * distanceFromCenter) * 0.25;
            z = (Math.sin(radians) * distanceFromCenter) * 0.25;
            y = (((stack.topPoint!.heightFromFloor * 0.25) - (binData!.eaveHeight * 0.25)) / 2);

            return new Vector3(x, y, z);
        }
    }
    private stackLabelVisibility = (show: boolean, ignoredIds: string[]) => {
        
        for (let label of this.stackTextArr) {
            label.visible = ignoredIds.find( b => b === label.name) == null;
        }
    }

    private segmentLabelVisibility = (show: boolean) => {
        for (let label of this.segmentTextArr) {
            label.visible = show;
        }
    }
    private layerMeshVisibility = (show: boolean) => {
        this.components.clearBin.visible = show;
        for (let layer of this.layerMeshArr) {
            layer.visible = show;
        }
    }
    private arrowVisibility = (show: boolean) => {
        for (let line of this.arrowCurveGroupArr) {
            line.visible = show;
        }
    }

    private toggleAllGates = (show: boolean) => {
        for (let gate of this.gateCoverArr) {
            this.setGateSpoolVisuals(gate, show, this.allGatesSpools.isSpoolsOpen);
        }
    }

    private toggleAllSpools = (show: boolean) => {
        for (let gate of this.gateCoverArr) {
            this.setGateSpoolVisuals(gate, this.allGatesSpools.isGatesOpen, show);
        }
    }

    private addGatesToStacks = (stackInfo: StackDTO, stackClone: Mesh, binData: BinDTO) => {
        let valves = stackInfo.valves;
        var BoxGeometry = new THREE.BoxGeometry();
        var BoxMaterial = new MeshBasicMaterial({ color: 0x000000 });

        valves!.forEach((valve, i) => {
            var closedBoxGrouping = new Group();
            let gateCoverClone = this.components.gateCover.clone();
            gateCoverClone.material = this.components.gateCover.material.clone();
            gateCoverClone.name = valve.id;
            gateCoverClone.scale.set(2.5, 0.04, 2.5);
            var y = this.getGateTranslationAmount(stackInfo, valve);
            gateCoverClone.position.y = y;
            var box = new Mesh(BoxGeometry, BoxMaterial);
            box.name = 'gate-open';
            box.scale.set(0.63, 0.5, 0.63);
            box.position.set(0, 0.75, 0);
            gateCoverClone.add(box);

            var closedBoxTop = new Mesh(BoxGeometry, BoxMaterial);
            closedBoxTop.scale.set(1.4, 0.140, 1.1);
            closedBoxTop.position.y = 0.546;

            var closedBoxSide = new Mesh(BoxGeometry, BoxMaterial);
            closedBoxSide.scale.set(1.4, 0.680, .1);
            closedBoxSide.position.set(0, 0.276, -0.598);
            var closedBoxSideClone = closedBoxSide.clone();
            closedBoxSideClone.position.z = 0.598;
            closedBoxGrouping.add(closedBoxTop, closedBoxSide, closedBoxSideClone);
            closedBoxGrouping.name = 'gate-closed';
            gateCoverClone.add(closedBoxGrouping);

            this.addArrowHelpers(valve, gateCoverClone);
            if (i + 1 !== valves?.length) {
                this.createArrowCurves(valve, valves![i + 1], gateCoverClone, stackClone, stackInfo);
            }
            this.setGateSpoolVisuals(gateCoverClone, valve.isGateOpen, valve.isSpoolOpen);
            this.gateCoverArr.push(gateCoverClone);
            stackClone.add(gateCoverClone);
            let highlightColor = `hsl(219, 44% , 80%)`;
            this.highlightArr.push({
                key: valve.id!,
                type: 'valve',
                defaultColor: GATECOLOR,
                highlightColor: highlightColor,
                object: gateCoverClone
            });

        });
    }

    private addRHTOrRFText = (moistureCable: MoistureCableDTO | MoistureCableRFDTO, cableClone: Mesh, RHT: RHTorSensorTextAddInputs) => {
        let id = RHT.id;
        let y = this.getRHTTranslationAmount(moistureCable, RHT); // 0.1;

        const preferredSensorType = this.state.bin?.temperatureSensorsType ?? getDefaultTemperatureSensorType(this.state.bin!);
        if ([TemperatureSensorEnum.Opi, TemperatureSensorEnum.PowerCast, TemperatureSensorEnum.BinSense].includes(preferredSensorType)) {
            const temperature = RHT.temperature;
            let spriteTemp = new SpriteText2D(
                `${formatNumber(temperature, { decimalPlaces: 0, filler: "", suffix: "°F", showSuffixIfNoValue: true})}`,
                {
                    align: textAlign.center,
                    font: '54px Helvetica',
                    fillStyle: '#FFFFFF',
                    backgroundColor: '#000000',
                    antialias: true,

                });
            spriteTemp.name = id!;
            spriteTemp.position.set(0, y, 3);
            spriteTemp.scale.set(.044, .000754, .044);
            this.stackTextArr.push(spriteTemp);
            cableClone.add(spriteTemp);
        }


        var MC = RHT.mc;
        let spriteMC = new SpriteText2D(
            `${formatNumber(MC, {decimalPlaces: 1, filler: "", suffix: "%"})}`,
            {
                align: textAlign.center,
                font: '54px Helvetica',
                fillStyle: '#FFFFFF',
                backgroundColor: '#000000',
                antialias: true,

            });
        spriteMC.name = id!;
        y = this.getRHTTranslationAmount(moistureCable, RHT); // 0.1;
        spriteMC.position.set(0, y, -3);
        spriteMC.scale.set(.044, .000754, .044);
        this.stackTextArr.push(spriteMC);
        cableClone.add(spriteMC);

    }

    private addMoistureCableText = (moistureCable: MoistureCableDTO , cableClone: Mesh, binData: BinDTO) => {
        let RHTS = moistureCable.rhtStates;
        const ignoredSensors = binData?.desiredProperties?.ignoredSensors ?? []
        RHTS!.forEach((RHT, i) => {
            this.addRHTOrRFText(moistureCable, cableClone, RHT);
        });
    }

    private addMoistureCableRFText = (moistureCableRF: MoistureCableRFDTO , cableClone: Mesh, binData: BinDTO) => {
        let wirelessRFs = moistureCableRF.wirelessStates;
        const ignoredSensors = binData?.desiredProperties?.ignoredSensors ?? []
        wirelessRFs!.forEach((rfSensor, i) => {
            this.addRHTOrRFText(moistureCableRF, cableClone, rfSensor);
        });
    }

    private addTempCableText = (tempCable: TemperatureCableDTO, cableClone: Mesh, binData: BinDTO) => {
        let thermocouples = tempCable.thermocouples;
        const ignoredSensors = binData?.desiredProperties?.ignoredSensors ?? [];
        thermocouples!.forEach((thermocouple, i) => {
            let id = thermocouple.id;
            var temperature = thermocouple.temperature != null ? thermocouple.temperature : 0.0;
            let sprite = new SpriteText2D(
                `${Math.ceil(temperature).toString()}°F`,
                {
                    align: textAlign.center,
                    font: '54px Helvetica',
                    fillStyle: '#FFFFFF',
                    backgroundColor: '#000000',
                    antialias: true,
                });
            sprite.name = id!;
            let y = this.getThermoTranslationAmount(tempCable, thermocouple); // 0.1;
            sprite.position.set(0, y, 2);
            sprite.scale.set(.044, .000754, .044);
            sprite.visible = ignoredSensors.find( b => b === thermocouple.id) == null;
            this.stackTextArr.push(sprite);
            cableClone.add(sprite);
        });
    }

    private addStackText = (stack: StackDTO, stackClone: Mesh, binData: BinDTO) => {
        // let thermocouples = stack.thermocouples;
        const { topTemp, topRH } = stack; // thermocouples,
        // thermocouples!.forEach((thermocouple, i) => {
        //     let id = thermocouple.id;
        //     let sprite = new SpriteText2D(
        //         `${Math.ceil(thermocouple.temperature).toString()}°F`,
        //         {
        //             align: textAlign.center,
        //             font: '54px Helvetica',
        //             fillStyle: '#FFFFFF',
        //             backgroundColor: '#000000',
        //             antialias: true,

        //         });
        //     sprite.name = id!;
        //     let y = this.getThermoTranslationAmount(stack, thermocouple);
        //     sprite.position.set(0, y, 2);
        //     sprite.scale.set(.044, .000754, .044);
        //     this.stackTextArr.push(sprite);
        //     stackClone.add(sprite);

        // });

        let idTop = `${stack.id} Temp`;
        let topTempText = "No temp";
        if (topTemp != null) {
            topTempText = ` ${Math.ceil(topTemp).toString()}°F `;
        }
        let spriteTop = new SpriteText2D(
            topTempText,
            {
                align: textAlign.center,
                font: '54px Helvetica',
                fillStyle: '#FFFFFF',
                backgroundColor: '#000000',
                antialias: true,

            });
        spriteTop.name = idTop;
        let yTop = .5;
        spriteTop.position.set(4, yTop, 0);
        spriteTop.scale.set(.044, .000754, .044);
        this.stackTextArr.push(spriteTop);
        stackClone.add(spriteTop);

        let idTopRH = `${stack.id} RH`;

        let topRHText = "";
        if (topRH == null) {
            topRHText = "No RH";
        }
        else {
            topRHText = `${Math.ceil(topRH).toString()}% RH`;
        }
        let spriteRH = new SpriteText2D(
            topRHText,
            {
                align: textAlign.center,
                font: '54px Helvetica',
                fillStyle: '#FFFFFF',
                backgroundColor: '#000000',
                antialias: true,

            });
        spriteRH.name = idTopRH;
        spriteRH.position.set(0, .6, 0);
        spriteRH.scale.set(.044, .000754, .044);
        this.stackTextArr.push(spriteRH);
        stackClone.add(spriteRH);
    }

    private addCO2Text = (binData: BinDTO, eaveClone: Mesh) => {
        const { cO2Level } = binData;

        let idTopCO2 = `CO2`;
        let spriteCO2 = new SpriteText2D(
            ` CO2 ${cO2Level ?? "___"} PPM `,
            {
                align: textAlign.center,
                font: '54px Helvetica',
                fillStyle: '#FFFFFF',
                backgroundColor: '#000000',
                antialias: true,

            });
        spriteCO2.name = idTopCO2;
        spriteCO2.position.set(0, 5, 0);
        spriteCO2.scale.set(.0067, .067, .067);
        this.stackTextArr.push(spriteCO2.clone());
        this.layerMeshArr.push(spriteCO2.clone());
        if(cO2Level == null){
            spriteCO2.visible = false;
        }
        eaveClone.add(spriteCO2);
    }

    private getRHTTranslationAmount = (cable: MoistureCableDTO | MoistureCableRFDTO, rht: RHTorSensorTextAddInputs) => {

        let height = rht.location!.heightFromFloor;

        let heightPercent = height / cable.height;

        return heightPercent - 0.5;
    }

    private getThermoTranslationAmount = (cable: TemperatureCableDTO, thermocouple: ThermocoupleDTO) => {

        let height = thermocouple.location!.heightFromFloor;

        let heightPercent = height / cable.height;

        return heightPercent - 0.5;
    }

    private getGateTranslationAmount = (stackInfo: StackDTO, valve: ValveDTO) => {
        let heightPercent = valve.location!.heightFromFloor / stackInfo.topPoint!.heightFromFloor;

        return heightPercent - 0.5;
    }

    private addArrowHelpers = (valve: ValveDTO, gateCover: Mesh) => {

        // make the arrows for take-in group
        let takeInGrouping = new Group();
        takeInGrouping.name = 'take-in-arrows';
        let upArrowGrouping = new Group();
        upArrowGrouping.name = 'up-arrows';
        let exhaustArrowGrouping = new Group();
        exhaustArrowGrouping.name = 'exhaust-arrows';
        let material = new LineBasicMaterial({ color: 0xff0000, linewidth: 1 });

        // add exhaust arrow groupings
        let exhaustCurve = new QuadraticBezierCurve3(
            new Vector3(.45, -.2, 0),
            new Vector3(.45, -.8, 0),
            new Vector3(1, -1.3, 0)
        );
        let arrowPoints = [
            new Vector3(.8, -.8, 0),
            new Vector3(1, -1.3, 0),
            new Vector3(.6, - 1.2, 0)
        ];

        let exhaustCurveL = new QuadraticBezierCurve3(
            new Vector3(-.45, -.2, 0),
            new Vector3(-.45, -.8, 0),
            new Vector3(-1, -1.3, 0)

        );
        let arrowPointsL = [
            new Vector3(-.8, -.8, 0),
            new Vector3(-1, -1.3, 0),
            new Vector3(-.6, - 1.2, 0)
        ];
        let points = exhaustCurve.getPoints(50);
        let geometry = new BufferGeometry().setFromPoints(points);
        let geometry1 = new BufferGeometry().setFromPoints(arrowPoints);
        let exhaustLine = new Line(geometry, material);
        let exhaustLineArrow = new Line(geometry1, material);

        let pointsL = exhaustCurveL.getPoints(50);
        let geometryL = new BufferGeometry().setFromPoints(pointsL);
        var geometry1L = new BufferGeometry().setFromPoints(arrowPointsL);
        var exhaustLineL = new Line(geometryL, material);
        var exhaustLineArrowL = new Line(geometry1L, material);
        exhaustArrowGrouping.add(exhaustLine, exhaustLineArrow, exhaustLineL, exhaustLineArrowL);
        // add take in arrows
        var takeInCurve = new QuadraticBezierCurve3(
            new Vector3(.55, -.55, 0),
            new Vector3(.55, -1.2, 0),
            new Vector3(1.1, -1.7, 0)
        );

        var takeInCurveL = new QuadraticBezierCurve3(
            new Vector3(-.55, -.55, 0),
            new Vector3(-.55, -1.2, 0),
            new Vector3(-1.1, -1.7, 0)
        );

        var takeInArrow = [
            new Vector3(.45, -.85, 0),
            new Vector3(.55, -.55, 0),
            new Vector3(.7, -.8, 0)
        ];

        var takeInArrowL = [
            new Vector3(-.45, -.85, 0),
            new Vector3(-.55, -.55, 0),
            new Vector3(-.7, -.8, 0)
        ];

        var takeInPoints = takeInCurve.getPoints(50);
        var takeInGeometry = new BufferGeometry().setFromPoints(takeInPoints);
        var takeInArrowGeometry = new BufferGeometry().setFromPoints(takeInArrow);
        var takeInLine = new Line(takeInGeometry, material);
        var takeInLineArrow = new Line(takeInArrowGeometry, material);

        var takeInPointsL = takeInCurveL.getPoints(50);
        var takeInGeometryL = new BufferGeometry().setFromPoints(takeInPointsL);
        var takeInArrowGeometryL = new BufferGeometry().setFromPoints(takeInArrowL);
        var takeInLineL = new Line(takeInGeometryL, material);
        var takeInLineArrowL = new Line(takeInArrowGeometryL, material);

        takeInGrouping.add(takeInLine, takeInLineArrow, takeInLineL, takeInLineArrowL);
        // add up arrow
        var upLine = [
            new Vector3(0, .6, .7),
            new Vector3(0, 2, .7)
        ];
        var upArrow = [
            new Vector3(-.2, 1.6, .7),
            new Vector3(0, 2, .7),
            new Vector3(.2, 1.6, .7)
        ];
        var upLineGeometry = new BufferGeometry().setFromPoints(upLine);
        var upArrowGeometry = new BufferGeometry().setFromPoints(upArrow);
        var upLineObj = new Line(upLineGeometry, material);
        var upArrowObj = new Line(upArrowGeometry, material);
        upArrowGrouping.add(upLineObj, upArrowObj);

        gateCover.add(takeInGrouping, upArrowGrouping, exhaustArrowGrouping);

    }

    private createArrowCurves = (valve: ValveDTO, valveAbove: ValveDTO, gateCover: Mesh, stackClone: Mesh, stackInfo: StackDTO) => {
        var material = new LineBasicMaterial({ color: 0x0000ff, linewidth: 14 });
        var height = this.getGateTranslationAmount(stackInfo, valve);
        var heightAbove = this.getGateTranslationAmount(stackInfo, valveAbove);
        var difInHeight = heightAbove - height;
        var lineGroup = new Group();
        lineGroup.name = valve.id + ' ' + valveAbove.id;
        var curve = new CatmullRomCurve3([
            new Vector3(.5, height, 0),
            new Vector3(1.5, height - .05, 0),
            new Vector3(3.5, height, 0),
            new Vector3(2.5, heightAbove - difInHeight / 2, 0),
            new Vector3(2, heightAbove - difInHeight * (1 / 4), 0),
            new Vector3(1.5, heightAbove - difInHeight * (1 / 8), 0)
        ]);
        var curve2 = new CatmullRomCurve3([
            new Vector3(-.5, height, 0),
            new Vector3(-1.5, height - .05, 0),
            new Vector3(-3.5, height, 0),
            new Vector3(-2.5, heightAbove - difInHeight / 2, 0),
            new Vector3(-2, heightAbove - difInHeight * (1 / 4), 0),
            new Vector3(-1.5, heightAbove - difInHeight * (1 / 8), 0)
        ]);

        var points = curve.getPoints(50);
        var points2 = curve2.getPoints(50);

        var arrow = [
            new Vector3(2.5, heightAbove - difInHeight * (1 / 6), 0),
            new Vector3(1.5, heightAbove - difInHeight * (1 / 8), 0),
            new Vector3(1.3, heightAbove - difInHeight * (1 / 5), 0)
        ];

        var arrow2 = [
            new Vector3(-2.5, heightAbove - difInHeight * (1 / 6), 0),
            new Vector3(-1.5, heightAbove - difInHeight * (1 / 8), 0),
            new Vector3(-1.3, heightAbove - difInHeight * (1 / 5), 0)
        ];
        var geometry = new BufferGeometry().setFromPoints(points);
        var spline = new Line(geometry, material);
        var arrowGeometry = new BufferGeometry().setFromPoints(arrow);
        var lineArrow = new Line(arrowGeometry, material);

        var geometry2 = new BufferGeometry().setFromPoints(points2);
        var spline2 = new Line(geometry2, material);
        var arrowGeometry2 = new BufferGeometry().setFromPoints(arrow2);
        var lineArrow2 = new Line(arrowGeometry2, material);
        lineGroup.add(spline, lineArrow, spline2, lineArrow2);
        lineGroup.visible = false;
        stackClone.add(lineGroup);
        this.arrowCurveGroupArr.push(lineGroup);

    }

    private setGateSpoolVisuals = (gateCover: Object3D, isGateOpen: boolean, isSpoolOpen: boolean) => {
        var takeInArrow = gateCover.getObjectByName('take-in-arrows');
        var upArrow = gateCover.getObjectByName('up-arrows');
        var exhaustArrow = gateCover.getObjectByName('exhaust-arrows');
        var gateOpen = gateCover.getObjectByName('gate-open');
        var gateClosed = gateCover.getObjectByName('gate-closed');

        if (isGateOpen) {
            if (isSpoolOpen) {
                takeInArrow!.visible = true;
                upArrow!.visible = true;
                exhaustArrow!.visible = false;
                gateOpen!.visible = true;
                gateClosed!.visible = false;
            } else {
                takeInArrow!.visible = false;
                upArrow!.visible = true;
                exhaustArrow!.visible = false;
                gateOpen!.visible = true;
                gateClosed!.visible = false;
            }
        } else {
            if (isSpoolOpen) {
                takeInArrow!.visible = false;
                upArrow!.visible = false;
                exhaustArrow!.visible = true;
                gateOpen!.visible = false;
                gateClosed!.visible = true;
            } else {
                takeInArrow!.visible = false;
                upArrow!.visible = false;
                exhaustArrow!.visible = false;
                gateOpen!.visible = false;
                gateClosed!.visible = true;
            }
        }

    }

    private renderScene = () => {
        if (this.renderer) {
            this.renderer.render(this.scene, this.camera);
        }
    }

    private onContainerResize = () => {
        let container = this.renderer.domElement.parentElement;
        let box = container!.getBoundingClientRect();
        this.renderer.setSize(box.width, box.height);

        this.camera.aspect = box.width / box.height;
        this.camera.updateProjectionMatrix();

    }

    private animate = () => {

        requestAnimationFrame(this.animate);
        // required if controls.enableDamping or controls.autoRotate are set to true
        var cameraPos = this.camera.position;
        this.controls.update();

        for (let gate of this.gateCoverArr) {
            var gateClosed = gate.getObjectByName('gate-closed');
            let gatePos = new Vector3();
            gate.getWorldPosition(gatePos);
            gateClosed!.lookAt(new Vector3(cameraPos.x, gatePos.y, cameraPos.z));
            gateClosed!.rotateY(Math.PI / 2);

        }

        for (let stack of this.stackArr) {
            let stackPos = new Vector3();
            stack.getWorldPosition(stackPos);
            stack.lookAt(new Vector3(cameraPos.x, stackPos.y, cameraPos.z));
        }

        let worldPos = new Vector3();
        this.components.clearBin.getWorldPosition(worldPos);
        this.components.clearBin.lookAt(new Vector3(cameraPos.x, worldPos.y, cameraPos.z));
        this.components.alwaysVisibleClearBin.getWorldPosition(worldPos);
        this.components.alwaysVisibleClearBin.lookAt(new Vector3(cameraPos.x, worldPos.y, cameraPos.z));
        this.renderer.render(this.scene, this.camera);
    }
}

//export const BinVisualThree = withIdleTimer<StatProps>(BinVisualThreeUnwrapped);
export const BinVisualThree = BinVisualThreeUnwrapped;